import { Fragment } from "react";
import { useTranslation } from 'react-i18next';

import { marks } from "../../utils/marks";
import {
  confirmModalStyle,
  confirmModalMobileStyle,
  confirmModalWideStyle,
} from "./modalStyle";

import classes from './ModalConfirm.module.css';

function ModalConfirm(props) {
  // console.log("modalConfirm-props", props);
  const { 
    onClose, 
    onAccept, 
    hideButtons,
    isLoading 
  } = props;

  const [t] = useTranslation('translation');

  let confirModalStyle = confirmModalStyle;

  if (window.innerWidth < 480 || window.innerHeight < 480) {
    confirModalStyle = confirmModalMobileStyle;
  }

  if (window.innerWidth > 768) {
    confirModalStyle = confirmModalWideStyle;
  }

  const acceptHandler = () => {
    if (!isLoading) {
      onAccept();
    }
  };

  const closeHandler = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <Fragment>
      <div style={confirModalStyle}>
        <div className={classes.modalClose}>
          <strong className={classes.modalCloseButton}
            onClick={closeHandler}
          >
            {marks.closeMark}
          </strong>
        </div>

        <div className={classes.modalConfirmMessage}>
          {props.children}
        </div>

        {!hideButtons && (
          <div className={classes.modalConfirmActionButtons}>
            <button className="btnBase"
              disabled={isLoading}
              onClick={closeHandler}
            >
              {t('general.text1', 'Cancel')}
            </button>
            <button className="btnBase"
              disabled={isLoading}
              onClick={acceptHandler}
            >
              {t('general.text2', 'Accept')}
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ModalConfirm;
