import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import numeral from 'numeral';
// import axios from 'axios';

import Backdrop from '../../Backdrop/Backdrop';
import ModalConfirm from '../../Modal/ModalConfirm';
import SizesContent from '../SizesContent/SizesContent';
import SizesSummary from '../SizesContent/SizesSummary';
import ShareNotifications from '../ShareBuckets/ShareNotification/ShareNotifications';

import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import FilesUpload from '../ObjectControl/FilesUpload';
import CreateFolder from '../ObjectControl/CreateFolder';
import MeetingInfo from '../MeetingInfo/MeetingInfo';
// import { BACKEND_URL } from '../../../App';

import { marks } from '../../../utils/marks';
import classes from './BucketObjectMenu.module.css';


function BucketObjectMenu(props) {
  const {
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const {
    bucketName, 
    bucketSizeGB,
    totalSizeGB,
    limitGB,
    displayTable,
    showMeetingInfo,
   } = store.bucketObjectStore;
  // const { subscriptions } = store.stripeData;
  const { gLoading, isAuth } = store.uiStore;
  const { 
    showShareNotifications,
    shareNotification,
    notOpenShareNotificationExist,
  } = store.shareObjectStore;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
 
  // const [modalPositions, setModalPositions] = useState({ 
  //   top: null, 
  //   left: null,
  //   right: null,
  //   bottom: null,
  //   heightLimit: 300,
  // });

  // const [showObjectControl, setShowObjectControl] = useState(false);
  const [showDropArea, setShowDropArea] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showSizesSummary, setShowSizesSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   const activeSubscription = subscriptions.find(subscription => {
  //     return subscription.status === 'active';
  //   });
  
  //   if (activeSubscription) {
  //     const subLimitGB = Number(activeSubscription.metadata.storeSizeGB);
      
  //     if (subLimitGB > 0) {
  //       dispatch('SET_LIMITGB', subLimitGB);
  //     }
  //   }
  //   else {
  //     dispatch('SET_LIMITGB', freeSizeGB);
  //   }
  // },[subscriptions]);

  // const showObjectControlHandler = (value) => {
  //   // setShowObjectControl(value);
  //   dispatch('SET_SHOWOBJECTCONTROL', value);

  //   const targetEl = document.getElementById(`object-control`);
  //   const targetRect = targetEl.getBoundingClientRect();

  //   console.log(targetEl);
  //   console.log(targetRect);

  //   console.log(targetRect.bottom, window.innerHeight - targetRect.bottom, window.innerHeight)
    
  //   // const heightLimit = 300;

  //   setModalPositions({
  //     ...modalPositions,
  //     top: targetRect.bottom,
  //     heightLimit: window.innerHeight - targetRect.bottom,
  //   })

  // };


  if (!isAuth || !bucketName || window.location.pathname !== '/') {
    return null;
  }

  return (
    <Fragment>
      {!displayTable && (
          <div className={classes.objectControlListItem}
            onClick={() => { 
              if (!isLoading) {
                setShowDropArea(true); 
                // showObjectControlHandler(false);
              }
            }}
          >
            {marks.uploadMark} {t('objectControl.02', 'upload files')}
          </div>
        )}
        {!displayTable && (
          <div className={classes.objectControlListItem}
            onClick={() => { 
              if (!isLoading) {
                setShowCreateFolder(true); 
                // showObjectControlHandler(false);
              }
            }}
          >
            {marks.folderFillMark} {t('objectControl.03', 'create folder')}
          </div>
        )}
       


      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_DISPLAYTABLE', ''); 
            // showObjectControlHandler(false);
          }
        }}
      >
        {marks.tableMark} {t('objectControl.05', 'files')}
      </div>
      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_DISPLAYTABLE', 'recent'); 
            // showObjectControlHandler(false);
          }
        }}
      >
        {marks.replyMark} {t('objectControl.04', 'recent visit')}
      </div>
      
      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_DISPLAYTABLE', 'share-files'); 
            // showObjectControlHandler(false);
          }
        }}
      >
        {marks.usersMark} {t('objectControl.12', 'sharing files')} 
      </div>
      
      {shareNotification && shareNotification.shareNotifications.length > 0 && (
        <div className={classes.objectControlListItemContainer}>
          <div className={classes.objectControlListItem}
            onClick={() => { 
              if (!isLoading) {
                dispatch('SET_SHOWSHARENOTIFICATIONS', true);
              }
            }}
          >
            {t('objectControl.13', 'share notification')}
          </div>
          {notOpenShareNotificationExist && (
            <span className={classes.objectControlListItemNotifyMark}>
              new
            </span>
          )}
        </div>
      )}

      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_SHOWMEETINGINFO', true);
          }
        }}
      >
        {marks.videoCameraMark} {t('objectControl.14', 'video meeting')}
      </div>

      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_DISPLAYTABLE', 'trashbox'); 
            // showObjectControlHandler(false);
          }
        }}
      >
        {marks.trashMark} {t('trash.14', 'trash box')}
      </div>

      <div className={classes.objectControlListItem}>
        <SizesContent 
          setShowSizesSummary={setShowSizesSummary}
        />
      </div>

      {showSizesSummary && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { 
              if (!isLoading) {
                setShowSizesSummary(false);
              }
            }}
          />
        <ModalConfirm 
        hideButtons={true}
        onClose={() => { 
          if (!isLoading) {
            setShowSizesSummary(false);
          } 
        }}
        >
          <SizesSummary />
        </ModalConfirm>
        </div>
      )}


      {showDropArea && (
        <FilesUpload 
          setShowDropArea={setShowDropArea}
        />
      )}
      {showCreateFolder && (
        <CreateFolder 
          setShowCreateFolder={setShowCreateFolder}
        />
      )}

      {showShareNotifications && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { 
              if (!isLoading) {
                dispatch('SET_SHOWSHARENOTIFICATIONS', false);
              }
            }}
          />
          <ModalConfirm 
            hideButtons={true}
            onClose={() => { 
              if (!isLoading) {
                dispatch('SET_SHOWSHARENOTIFICATIONS', false);
              } 
            }}
          >
            <ShareNotifications />
          </ModalConfirm>
        </div>
      )}

      {showMeetingInfo && (
        <MeetingInfo />
      )}

    </Fragment>
  );
}

export default BucketObjectMenu;
