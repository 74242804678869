import { useEffect, useState, Fragment } from "react";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';

import { useStore } from "../../../hook-store/store";
// import { BACKEND_URL } from '../../../App';

import classes from './AdminBucketSearch.module.css';

const searchBoxStyle = {
    // height: "35px",
    height: "2rem",
    // height: "2.5rem",
    border: "1px solid #dfe1e5",
    // border: "1px solid gray",
    // borderRadius: "24px",
    borderRadius: "6px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    // fontSize: "16px",
    fontSize: "1rem",
    fontFamily: "Arial",
    iconColor: "grey",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    // clearIconMargin: '3px 14px 0 0',
    clearIconMargin: '3px 5px 0 0',
    // searchIconMargin: '0 0 0 16px',
    searchIconMargin: '0 0 0 5px',

};

function AdminBucketSearch(props) {
  // console.log('ObjectSearch.js-props', props);
  const { buckets, setSearchSelect } = props;

  const [store, dispatch] = useStore();
  // console.log(store);
  // const allBucketObjects = store.bucketObjectStore.allBucketObjects;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const currentPrefix = store.bucketObjectStore.currentPrefix;

  // const [searchList, setSearchList] = useState([]);

  // useEffect(() => {
  //   if (allBucketObjects.length > 0) {
  //     const listForSearch = createSearchList(allBucketObjects);
  //     setSearchList(listForSearch);
  //   }

  // },[allBucketObjects]);
  
  const searchList = buckets.map((element, index) => {
    return {
      ...element,
      id: index,
    };

    // if (element.Key.endsWith('/')) {
    //   return {
    //     id: index,
    //     Key: element.Key,
    //     name: element.Key.slice(0, element.Key.length - 1),
    //     LastModified: element.LastModified,
    //     Size: element.Size
    //   }
    // } 
    // else {
    //   const timeEndIndex = element.Key.indexOf('Z');
    //   const originalName = element.Key.substring(timeEndIndex+2);

    //   return {
    //     id: index,
    //     Key: element.Key,
    //     name: originalName,
    //     LastModified: element.LastModified,
    //     Size: element.Size
    //   }
    // }
  });
 
  // const createSearchList = (array) => {
  //   const list = array.map((element, index) => {
  //     if (element.Key.endsWith('/')) {
  //       return {
  //         id: index,
  //         key: element.Key,
  //         name: element.Key.slice(0, element.Key.length - 1),
  //         lastModified: element.LastModified,
  //         size: element.Size
  //       }
  //     } 
  //     else {
  //       const timeEndIndex = element.Key.indexOf('Z');
  //       const originalName = element.Key.substring(timeEndIndex+2);
  
  //       return {
  //         id: index,
  //         key: element.Key,
  //         name: originalName,
  //         lastModified: element.LastModified,
  //         size: element.Size
  //       }
  //     }
  //   });

  //   return list;
  // }


  // console.log(list);

  // const items = [
  //   {
  //     id: 0,
  //     name: 'Cobol',
  //     type: 'co',
  //   },
  //   {
  //     id: 1,
  //     name: 'JavaScript',
  //     type: 'js'
  //   },
  //   {
  //     id: 2,
  //     name: 'Basic',
  //     type: 'ba',
  //   },
  //   {
  //     id: 3,
  //     name: 'PHP',
  //     type: 'ph',
  //   },
  //   {
  //     id: 4,
  //     name: 'Java',
  //     type: 'jav',
  //   }
  // ];


  
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    // console.log(string, results)
    if (!string) {
      // setSearchSelectedItem(null);
    }
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log('selected item: ', item);
    setSearchSelect(item);
    // dispatch('SET_SEARCH_SELECTEDITEM', item);
    // setSearchSelectedItem(item);
    // lsStoreRecentSelect(item);
  }

  const handleOnFocus = () => {
    console.log('Focused')
    // console.log(items);
  }

  const handleOnClear = () => {
    console.log('Cleared')
    // console.log(items);
    // setSearchSelectedItem(null);
  }

  const formatResult = (item) => {
    // const prefixDivide = item.Key.split('/');
    // prefixDivide.pop();
    // const folderPlace = prefixDivide.join('/');

    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}><strong>{item.Key}</strong></span> */}
        <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
          <strong>{item.Name}</strong>
        </span>
        <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
          {item.CreationDate}
        </span>
        {/* {!item.Key.endsWith('/') && (
          <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", paddingLeft: "0.5rem", wordBreak:"break-all" }}>
            {folderPlace}
          </span>
        )} */}
        {/* <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", wordBreak:"break-all" }}>
          ({folderPlace})
        </span> */}
      </>
    )
  }


  return (
    <Fragment>
      {searchList.length > 0 && (
        <div className={classes.adminSearch}
          // style={{ width: 400 }}
        >
          <ReactSearchAutocomplete
            // items={items}
            items={searchList}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            // autoFocus
            formatResult={formatResult}

            // fuseOptions={{ keys: ["name"] }}
            fuseOptions={{ keys: ["Name"] }}
            resultStringKeyName="Name"

            placeholder="search-object"
            styling={searchBoxStyle}
          />
        </div>
      )} 
    </Fragment>
  );
}

export default AdminBucketSearch;
