// import MainNavigation from './mainNavigation/MainNavigation';
// import Footer from './Footer';
// import Language from '../language/Language';
// import GetConfig from '../getConfig';
// import SubNavigation from './subNavigation/SubNavigation';
// import SubNavigation from './subNavigation/SubNavigation';
import Footer from '../Footer/Footer';
import MainNav from '../Navigation/MainNav/MainNav';
import Notification from '../Notification/Notification';

import { useStore } from '../../hook-store/store';

import classes from './Layout.module.css';


// import { Link } from 'react-router-dom';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

function Layout(props) {
  // console.log('Layout.js-props', props);

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  const notification = store.uiStore.notification;

  // if (!store.roomConfig) {
  //   return <GetConfig />
  // }

  return (
    <div className={classes.AppContainer}>
      <MainNav />

      <main className={classes.main}>{props.children}</main>
      
      <Footer />
      {/* <Language />
      <Footer /> */}

      {notification && (
        <div>
          <Notification 
            status={notification.status}
            title={notification.title}
            message={notification.message}
          />
        </div>
      )}
    </div>
  );
}

export default Layout;
