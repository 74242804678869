import { useState, useEffect, Fragment } from 'react';
// import _, { isLength } from 'lodash';
// import axios from 'axios';

// import BucketObjectList from './BucketObjectList';
import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../utils/bucket-object-util';
// import * as fileMoveUtils from '../../utils/file-move-util';

// import { BACKEND_URL } from '../../App';
import { marks } from '../../../utils/marks';
import classes from './PrefixLine.module.css';

function PrefixLine(props) {

  const { 
    getBucketCommonPrefixes, 
    getAllBucketObjects,
    createToplevelsHandler,
    // isLoading,
  } = props;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;

  const { allBucketObjects } = store.bucketObjectStore;

  const gLoading = store.uiStore.gLoading;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  const splitArray = currentPrefix.split('/');

  const topLevelName = 'top';

  let prefixArrayBody;

  if (currentPrefix && splitArray.length > 0) {
    
    prefixArrayBody = (
      <div className={classes.prefixLine}>
        <span onClick={() => { 
            if (!isLoading) {
              // getBucketCommonPrefixes(bucketName); 
              createToplevelsHandler('', allBucketObjects);
            }
          }}
        >
          {marks.folderFillMark} {topLevelName} {' / '}
        </span>
        {' '}
        {splitArray.map((element, index) => {
          let prefix = splitArray[0] + '/';
          
          for (let i=0; i<index; i++) {
            if (element) {
              prefix = prefix + splitArray[i+1] + '/';
            }
          }
          // console.log(index, 'prefix: ', prefix, 'element: ', element);
          if (element) {
            // console.log(prefix, currentPrefix)
            return (
              <span key={index} 
                style={index === splitArray.length -2 ? {fontWeight:"bold"} : null}
                onClick={() => { 
                if (!isLoading && index < splitArray.length -2) {
                  // getAllBucketObjects(bucketName, prefix); 
                  createToplevelsHandler(prefix, allBucketObjects);
                }
                }}
              >
                {marks.folderFillMark} {element}{index < splitArray.length -2 && ' / '}
              </span>
            );
          } else {
            return null;
          }
      })}
      
      </div>
    );
  }

  
  return (
    <Fragment>
      {/* <div>prefix-line-body</div> */}
      {prefixArrayBody}
    </Fragment>
  );
}

export default PrefixLine;
