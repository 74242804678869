import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ALLOBJECTSMETALIST: (state, payload) => {
      return {
        // ...state,
        objectMetadataStore: {
          ...state.objectMetadataStore,
          allObjectsMetaList: payload,
        }
      };
    },

    SET_DATAFORMETADATA: (state, payload) => {
      return {
        // ...state,
        objectMetadataStore: {
          ...state.objectMetadataStore,
          dataForMetadata: payload,
        }
      };
    },

  };

  initStore(actions, {
    objectMetadataStore: {
      allObjectsMetaList: [],
      dataForMetadata: null,
    }
  });
};

export default configureStore;
