import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import Backdrop from '../../../components/Backdrop/Backdrop';
// import Card from '../../../components/ui/Card';
import ChangePlanModalContent from '../../../components/subscription/change-plan/ChangePlanModalContent';
import Loader from '../../../components/Loader/Loader';
// import ModalSimple from '../../../components/modal/ModalSimple';
import ModalConfirm from '../../../components/Modal/ModalConfirm';
import PricesDetail from '../../../components/subscription/prices/PricesDetail';
import { useStore } from '../../../hook-store/store';
import { fetchPrices } from '../../../utils/stripe';

import { 
  BACKEND_URL, 
  SUBSCRIPTION_BACKEND_URL,
  subscriptionBasicPriceId,
  subscriptionMiniPriceId,
 } from '../../../App';

import classes from './ChangePlan.module.css';

const ChangePlan = (props) => {
  // console.log('ChangePlan.js-props', props);
  // const lsCustomerId = localStorage.getItem('customerId');
  const location = useLocation();
  const navigate = useNavigate();

  const subscriptionId = location.state.subscription;

  const priceData = location.state.priceData;
  // console.log('ChangePlan.js-location', location);

  const [store, dispatch] = useStore();
  const { limitGB, totalSizeGB, freeSizeGB } = store.bucketObjectStore;
  const { basicSizeGB, miniSizeGB, dev02SizeGB } = store.stripeData;
  // console.log(state);

  // const history = useHistory();

  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState('');

  useEffect(() => {
    // const fetchPrices = async () => {
    //   // const {prices} = await fetch(BACKEND_URL + '/config').then(r => r.json());
    //   const {prices} = await fetch(BACKEND_URL + '/plan-prices').then(r => r.json());
      
    //   setPrices(prices);

    // };
    // fetchPrices();

    setIsLoading(true);

    fetchPrices()
      .then(res => {
        // console.log(res);
        setPrices(res.prices);
        setIsLoading(false);
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);


  const updateSubscription = async (priceId) => {
    
    // console.log(priceId, subscriptionId);
    // return;
    
    setIsLoading(true);

    const { 
      subscription, 
      error, 
    } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/update-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: priceId,
        subscriptionId: subscriptionId,
        customerId: localStorage.getItem('customerId'),
      }),
    }).then(r => r.json());

    if (error) {
      // setError(error.message);
      setError('Update Failed');
      setIsLoading(false);
    }

    if (subscription) {
      // console.log(subscription);
      setIsLoading(false);
      // history.push('/subscription/account');
      navigate('/subscription/account');
    }

  };


  return (
    <div>


      {showChangePlanModal &&
        <div>
          <Backdrop />
          <ModalConfirm hideButtons={true}>
            <ChangePlanModalContent
              updateSubscription={updateSubscription}
              selectedPriceId={selectedPriceId}
              setShowChangePlanModal={setShowChangePlanModal}
              error={error}
              isLoading={isLoading}
            />
          </ModalConfirm>
        </div>
      }
      {/* <Card> */}
      <div className="subscriptionContent">
        <div className={classes.subscriptionCardContents}>
          <h2>
            {/* Select a subscription type */}
            {i18next.t('subText13', 'Select a subscription type')}	 
          </h2>

          <div className="price-list">
            {prices.map((price) => {
              // console.log(price.recurring.trial_period_days)
              let subscriptionLimitGB = 0.2;
              let isLimitOver = true;
              
              if (price.id === subscriptionMiniPriceId) {
                subscriptionLimitGB = miniSizeGB;
              }

              if (price.id === subscriptionBasicPriceId) {
                subscriptionLimitGB = basicSizeGB;
              }

              if (totalSizeGB <= subscriptionLimitGB) {
                isLimitOver = false;
              }
              
              return (
                <div key={price.id} className={classes.planContainer}>
                  <h3>{price.product.name}</h3>

                  <p>
                    {/* ${price.unit_amount / 100} / month */}
                    ${price.unit_amount / 100} / {price.recurring.interval}
                  </p>

                  <button className={classes.subscriptionButton}
                    disabled={isLoading || priceData.id === price.id || isLimitOver}
                    // onClick={() => updateSubscription(price.id)}
                    onClick={() => {
                      setSelectedPriceId(price.id);
                      setShowChangePlanModal(!showChangePlanModal);
                      setError('');
                    }}
                  >
                    {/* Select */}
                    {i18next.t('subText14', 'Select')}
                  </button>

              
                  {isLimitOver && (
                    <div>
                      Total file sizes are more than the size of plan.
                    </div>
                  )}

                  <PricesDetail 
                    price={price}
                    isLoading={isLoading}
                  />

                  {/* <div>{error}</div> */}

                  {isLoading && <div className={classes.subscriptionLoader}><Loader /></div>}

                {/* {price.recurring.trial_period_days && 
                  <button 
                    disabled={isLoading}
                    onClick={() => createTrialSubscription(price.id)}
                  >
                    trial-test
                  </button>
                } */}
                </div>
              )
            })}
          </div>
          <div className={classes.currentPlan}>
            {/* Your current subscription type: {priceData && priceData.product.name} */}
            {i18next.t('subText24', 'Your current subscription type')}: 
          </div>
          <div className={classes.currentPlanContents}>
            {priceData && priceData.product.name}
          </div>

          <div>{error}</div>

          {isLoading && <div className={classes.subscriptionLoader}><Loader /></div>}
        </div>
      </div>

      {/* </Card> */}

    </div>
  );
}

export default ChangePlan;
