import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';

import { useStore } from '../../../../hook-store/store';

// import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './TrashExistKeysList.module.css';

// import ObjectControl from '../ObjectControl/ObjectControl';

function TrashExistKeysList(props) {

  const {
    existDestKeys,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  // const { 
  //   allBucketObjects,
  //   bucketName,
  // } =store.bucketObjectStore;

  // const { 
  //   allObjectsMetaList,
  // } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;

  // const [existDestKeys, setExistDestKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


 

  let trashExistKeysListBody;

  if (existDestKeys.length > 0) {
    trashExistKeysListBody = (
      <div>
        <div className={classes.trashExistKeysListContent}>
          {t('trash.08', 'Destination folders already exist (Name change or deletion of destination folder is required to restore).')} 
        </div>
        <div className={classes.trashExistKeysListContent}>
          <div>
            {t('trash.09', 'exist folders')}
          </div>
          <ul>
            {existDestKeys.map(key => {

              const splitArray = key.split('/');

              let displayName;

              if (key.split('/').length >= 2) {

                displayName = splitArray.map((element, index) => {
                  let prefix = splitArray[0] + '/';
                  
                  for (let i=0; i<index; i++) {
                    if (element) {
                      prefix = prefix + splitArray[i+1] + '/';
                    }
                  }
                  // console.log(index, 'prefix: ', prefix, 'element: ', element);
                  if (element) {
                    // console.log(prefix, currentPrefix)
                    const dispFolderName = element;
      
                    return (
                      <span key={index} 
                        // style={index === splitArray.length -2 ? {fontWeight:"bold"} : null}
                      >
                        {marks.folderFillMark} {dispFolderName}{index < splitArray.length -2 && ' / '}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })
              }

              return (
                <li key={key}>
                  <div>
                    {displayName}
                  </div>
                </li>
              );
            })}
          </ul>
          
        </div>
      </div>
    );
  }



  return (
    <Fragment>
      <div>{trashExistKeysListBody}</div>
    </Fragment>

   
  );
}

export default TrashExistKeysList;
