import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import { BACKEND_URL } from '../../../App';

function SelectedObjectsActions(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;
  const objectsActionType = store.bucketObjectStore.objectsActionType;

  const gLoading = store.uiStore.gLoading;

  // const [showFoldersModal, setShowFoldersModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // const showFoldersModalHandler = (value) => {
  //   if (!value) {
  //     // dispatch('SET_SELECTED_PREFIX', '');
  //   }
  //   setShowFoldersModal(value);
  // }


  // const deleteFilesFoldersHandler = async (bucket, prefixes, keys, topLevels) => {
  //   try {
  //     setIsLoading(true);
  //     dispatch('SET_GLOADING', true);

  //     console.log('delete file folder num', keys.length, prefixes.length);

  //     let deletedList;

  //     if (keys.length > 0) {
  //       // await deleteBucketObjectsHandler(bucket, keys);

  //       if (!bucket || !keys || keys.length === 0) {
  //         throw new Error('bucket and keys are required');
  //       };
  
  //       console.log(keys, bucket);
  //       const resData = await bucketObjectUtils.deleteBucketObjects(
  //         BACKEND_URL,
  //         localStorage.getItem('token'),
  //         bucket,
  //         keys,
  //       );
  
  //       console.log(resData);
        
  
  //       deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
  //       dispatch('SET_TOPLEVELS', deletedList);
  //       dispatch('SET_SELECTED_FILEKEYS',[]);

  //     }

  //     if (prefixes.length > 0) {

  //       const result = await bucketObjectUtils.deleteFolders(
  //         BACKEND_URL,
  //         localStorage.getItem('token'),
  //         bucket,
  //         prefixes,
  //       );
  
  //       console.log(result);
           
  //       let forTop = topLevels;
        
  //       if (deletedList) {
  //         forTop = deletedList
  //       }

  //       const deletedList2 = fileMoveUtils.createFoldersDeletedObjectList(
  //         prefixes, 
  //         forTop, 
  //         currentPrefix
  //       );

  //       dispatch('SET_TOPLEVELS', deletedList2);
  //       dispatch('SET_MOVEPREFIXES', []);
  
  //     }

  //     dispatch('SET_SELECTED_PREFIX', '');

  //     setIsLoading(false);
  //     dispatch('SET_GLOADING', false);

  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //     dispatch('SET_GLOADING', false);
  //   }
  // };



  const copyFilesFoldersHandler = async (bucket, prefixes, keys, targetPrefix) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileCopy: null,
        folderCopy: null,
      };

      // if (targetPrefix === currentPrefix) {
      //   throw new Error('targetPrefix should be defferent from currentPrefix')
      // };
      let combinedList = [];

      let addKeys = [];

      console.log(prefixes, keys);
      if (keys.length > 0) {
        const result = await bucketObjectUtils.copyBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
          targetPrefix,
        );
        
        console.log(result);
        
        resultsForAction.fileCopy = result;
        
        addKeys = addKeys.concat(result.dataForMetadata.addKeys);

        if (targetPrefix === currentPrefix) {
          const addObjectList = [];
          
          for (const object of result.copyResults) {
            addObjectList.push(object.copyParams);
          }
          
          // console.log(addObjectList);
          combinedList = topLevels.concat(addObjectList);
        };

        dispatch('SET_SELECTED_FILEKEYS', []);
      }

      if (prefixes.length > 0) {
        const result = await bucketObjectUtils.copyFolders(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          prefixes,
          targetPrefix,
        );
  
        console.log(result);

        resultsForAction.folderCopy = result;

        addKeys = addKeys.concat(result.dataForMetadata.addKeys);

        if (targetPrefix === currentPrefix) {
          const addObjectList = [];

          console.log(result.copyFoldersResults);

          for (const copyResults of result.copyFoldersResults) {
            // console.log(copyResults);

            const folderCopyResult = copyResults.copyFolderResults.find(cResult => {
              return cResult.params.copySourceKey === copyResults.inputKey;
            });

            // console.log(folderCopyResult);

            if (folderCopyResult) {
              addObjectList.push(folderCopyResult.params);
            }
          }

          // console.log(addObjectList);

          if (combinedList.length > 0) {
            combinedList = combinedList.concat(addObjectList);
          } else {
            combinedList = topLevels.concat(addObjectList);
          }
        }
  
        dispatch('SET_MOVEPREFIXES', []);
      }

      if (combinedList.length > 0) {
        dispatch('SET_TOPLEVELS', combinedList);
      }

      dispatch('SET_SELECTED_PREFIX', '');


      dispatch('SET_OBJECTACTION', {
        actionType: 'copy-selected-objects',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      console.log('addKeys', addKeys);

      const dataForMetadata = {
        addKeys: addKeys,
      };

      dispatch('SET_DATAFORMETADATA', dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'copy success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);



      setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SET_SHOWFOLDERSMODAL', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `copy failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };



  const moveDeleteFilesFoldersHandler = async (bucket, prefixes, keys, targetPrefix, topLevels) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);


      let resultsForAction = {
        fileMove: null,
        folderMove: null,
      };

      let addKeys = [];

      if (targetPrefix === currentPrefix) {
        throw new Error('target should be defferent from current folder')
      };

      console.log(prefixes, keys);

      let deletedList;

      if (keys.length > 0) {
        const result = await bucketObjectUtils.moveDeleteBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
          targetPrefix,
        );
  
        console.log(result);

        resultsForAction.fileMove = result;

        addKeys = addKeys.concat(result.dataForMetadata.addKeys);
  
        deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
        dispatch('SET_TOPLEVELS', deletedList);
        dispatch('SET_SELECTED_FILEKEYS', []);
      }

      if (prefixes.length > 0) {
        const result = await bucketObjectUtils.moveDeleteFolders(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          prefixes,
          targetPrefix,
        );
  
        console.log(result);

        resultsForAction.folderMove = result;
  
        addKeys = addKeys.concat(result.dataForMetadata.addKeys);

        let forTop = topLevels;

        if (deletedList) {
          forTop = deletedList;
        }

        const deletedList2 = fileMoveUtils.createFoldersDeletedObjectList(
          prefixes, 
          forTop, 
          currentPrefix
        );
        dispatch('SET_TOPLEVELS', deletedList2);
        dispatch('SET_MOVEPREFIXES', []);
      }

      dispatch('SET_SELECTED_PREFIX', '');


      dispatch('SET_OBJECTACTION', {
        actionType: 'move-selected-objects',
        delete: true,
        add: true,
        result: resultsForAction,
      });


      console.log('addKeys', addKeys);
      
      const dataForMetadata = {
        addKeys: addKeys,
      };

      dispatch('SET_DATAFORMETADATA', dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'move success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `move failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };











  let selectedObjectsActionsBody;

  selectedObjectsActionsBody = (
    <div>
        <div>
          {objectsActionType === 'copy' && (
            <button className="btnBase"
              disabled={isLoading}
              onClick={() => { 
                if (!isLoading) {
                  copyFilesFoldersHandler(
                    bucketName, 
                    movePrefixes, 
                    selectedFileKeys,
                    selectedPrefix,
                  ); 
                }
              }}
            >
              {t('selectedObject.03', 'copy')}
            </button>
          )}
          {objectsActionType === 'move-delete' && (
            <button className="btnBase"
              disabled={isLoading}
              onClick={() => { 
                if (!isLoading) {
                  moveDeleteFilesFoldersHandler(
                    bucketName, 
                    movePrefixes, 
                    selectedFileKeys,
                    selectedPrefix,
                    topLevels,
                  ); 
                }
              }}
            >
              {t('selectedObject.04', 'move')}
            </button>
          )}
        {/* <div>selected-perfix (destination): {selectedPrefix}</div> */}
      </div>
    </div>


  );


  
  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* {showFoldersModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                showFoldersModalHandler(false); 
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                showFoldersModalHandler(false); 
              }
            }}
          >
            <Folder />
          </Modal>
        </div>
      )} */}
      {/* <div 
        onClick={() => { 
          if (!isLoading) {
            showFoldersModalHandler(true); 
          }
        }}
      >
        open-folder-select-modal
      </div> */}
      {selectedObjectsActionsBody}
    </Fragment>
  );
}

export default SelectedObjectsActions;
