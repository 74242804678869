
export const updateShareMetadata = async (
  url, 
  token, 
  bucket, 
  key, 
  type, 
  action, 
  email,
) => {
  try {
    const result = await fetch(url + '/share-metadata', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        key: key,
        type: type,   // read, write
        action: action, // add, delete
        email: email,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const getUserShareMetaInfoList = async (url, token) => {
  try {
    const result = await fetch(url + `/share-bucket/user-share-meta-info-list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({})
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const updateShareMetadataAcceptId = async (
  url, 
  token, 
  bucket, 
  key, 
  userId, 
  action,
  notificationId,
) => {
  try { 

    const result = await fetch(url + '/share-metadata/update-share-metadata-accept-id', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        key: key,
        userId: userId,
        action: action,
        notificationId: notificationId,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.status === 404 && resData.error && resData.error.data && 
      resData.error.data.errorType === 'id-not-found-in-writeIds-and-readIds'
    ) {
      throw new Error('id-not-found-in-writeIds-and-readIds');
    }

    if (!result.ok) {
      throw new Error('error occured');
    }



    return resData;

  } catch(err) {
    console.log(err);
    throw(err);
  }
};


export const getShareOwnerInfoList = async (url, token) => {
  try {
    const result = await fetch(url + `/share-bucket/share-owner-info-list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({})
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const getSharingUserInfoList = async (url, token) => {
  try {
    const result = await fetch(url + `/share-bucket/sharing-user-info-list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({})
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};

// export const checkEmailUserExist = async (url, token, email) => {
//   try {
//     const result = await fetch(url + `/share-metadata/check-email-user-exist?email=${email}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//       // body: JSON.stringify({})
//     });

//     const resData = await result.json();
//     console.log(result, resData);

//     if (result.status === 404) {
//       throw new Error('email-user-not-found')
//     }
    
//     if (result.ok) {
//       return resData;
//     } else {
//       throw new Error('error occured');
//     }

//   } catch(err) {
//     console.log(err);
//     throw err;
//   }
// };


export const getSharingUsers = (shareInfoMeta, sharingUsers) => {

  let sharingReadUsers = [];
  let sharingWriteUsers = [];
  const notAcceptReadUsers = [];
  const notAcceptWriteUsers = [];

  const acceptUserIds = shareInfoMeta.acceptIds;
  const readUserIds = shareInfoMeta.readIds;
  const writeUserIds = shareInfoMeta.writeIds;

  for (const acceptUserId of acceptUserIds) {
    const acceptReadIds = shareInfoMeta.readIds.filter(id => {
      return id === acceptUserId;
    });

    const acceptWriteIds = shareInfoMeta.writeIds.filter(id => {
      return id === acceptUserId;
    });

    sharingReadUsers = acceptReadIds.map(id => {
      const sharingUser = sharingUsers.find(user => {
        return user.userId === id;
      });

      return {
        ...sharingUser,
        type: 'read',
      };
    });

    sharingWriteUsers = acceptWriteIds.map(id => {
      const sharingUser = sharingUsers.find(user => {
        return user.userId === id;
      });

      return {
        ...sharingUser,
        type: 'write',
      };
    });
  }

  for (const readId of readUserIds) {
    const isInAcceptIds = acceptUserIds.find(id => {
      return id === readId;
    });

    if (!isInAcceptIds) {
      const sharingUser = sharingUsers.find(user => {
        return user.userId === readId;
      });

      notAcceptReadUsers.push({
        ...sharingUser,
        type: 'read',
      });
    }
  }

  for (const writeId of writeUserIds) {
    const isInAcceptIds = acceptUserIds.find(id => {
      return id === writeId;
    });

    if (!isInAcceptIds) {
      const sharingUser = sharingUsers.find(user => {
        return user.userId === writeId;
      });

      notAcceptReadUsers.push({
        ...sharingUser,
        type: 'write',
      });
    }
  }

  return {
    sharingReadUsers: sharingReadUsers,
    sharingWriteUsers: sharingWriteUsers,
    notAcceptReadUsers: notAcceptReadUsers,
    notAcceptWriteUsers: notAcceptWriteUsers,
  }
};