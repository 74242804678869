import React from "react";
import { Fragment, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import i18next from 'i18next';

import { useStore } from "../../../hook-store/store"
import { transformLocaleDate } from '../../../utils/new-meetup';
import { fetchPrices } from "../../../utils/stripe";
import { BACKEND_URL} from '../../../App';

function AccountSubscription2(props) {
  // console.log("accountSubscripiton.js-props", props);

  const lsCustomerEmail = localStorage.getItem('customerEmail');
  const lsToken = localStorage.getItem('token');


  const [state, dispatch] = useStore();
  // console.log(state);

  const [latestInvoice, setLatestInvoice] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();

  // const priceData = state.stripeData.prices.find((ele) => {
  //   return ele.lookup_key === props.subscription.items.data[0].price.lookup_key;
  // });


  const priceData = state.stripeData.prices.find((ele) => {
    // console.log(ele.id);
    return ele.id === props.subscription.plan.id;
  });
  // console.log(props.subscription, priceData);
  // console.log(props.subscription.latest_invoice)

  useEffect(() => {

    // setIsLoading(true);

    fetchPrices()
      .then(res => {
        // console.log('fetchPrices in prices.js', res)
        // setPrices(res);
        // setPrices(res.prices);
        dispatch("SET_PRICES", res.prices);
        // setIsLoading(false);
      })
      .catch(err => {
        // console.log(err);
        // setIsLoading(false);
      });
  }, []);

  useEffect(() => {

    const retrieveLatestInvoice = async (invoiceId, token, email) => {
      const { invoice, error } = await fetch(BACKEND_URL + '/invoice/latest-invoice', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoiceId: invoiceId,
          email: email,
        }),
      })
      .then(r => r.json());

      if (error) {
        // console.log(error)
      }
      if (invoice) {
        // console.log(invoice);
        setLatestInvoice(invoice);
      }
    }


    const retrieveUpcomingInvoice = async (customerId, subscriptionId, priceId, token, email) => {
      const { invoice, error } = await fetch(BACKEND_URL + '/invoice/upcoming-invoice', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
          priceId: priceId,
          email: email,
        }),
      })
      .then(r => r.json());

      if (error) {
        // console.log(error)
      }
      if (invoice) {
        // console.log(invoice);
        setUpcomingInvoice(invoice);
      }
    }

    // console.log(props.subscription, priceData)
    retrieveLatestInvoice(
      props.subscription.latest_invoice, 
      lsToken,
      lsCustomerEmail
    );

    if (priceData) {
      retrieveUpcomingInvoice(
        localStorage.getItem('customerId'),
        props.subscription.id,
        priceData.id,
        lsToken,
        lsCustomerEmail
      );
    }

  },[]);

  return (
    <Fragment>
      <section>
        <hr />
        {/* <div>account-subscripition2</div> */}
        {/* <h4>
          <a
            href={`https://dashboard.stripe.com/test/subscriptions/${props.subscription.id}`}
          >
            {props.subscription.id}
          </a>
        </h4> */}

        <p>
          {/* Plan Name: {priceData && priceData.product.name} */}
          {i18next.t('subText4', 'Type')}: {priceData && priceData.product.name}
        </p>
        <p>
          {/* Price:{" "} */}
          {i18next.t('subText5', 'Price')}:{" "}
          {priceData &&
            `${
              priceData.unit_amount / 100
            } ${priceData.currency.toUpperCase()} / ${
              priceData.recurring.interval
            }`}
            <br />
        </p>

        <p>
          {/* Status: {props.subscription.status === 'past_due' */}
          {i18next.t('subText6', 'Status')}:{' '}
          {props.subscription.status === 'past_due'
            ? (
                <span>
                  {/* Payment failed */}
                  {i18next.t('subText17', 'Payment failed')}
                  {" "}
                  {latestInvoice &&
                    <a href={latestInvoice.hosted_invoice_url}
                      target="_blank" rel="noopener noreferrer"
                    >
                      {/* payment link */}
                      {i18next.t('subText18', 'Payment Link')}
                    </a>
                  }
                  {props.subscription.trial_end
                    ? (
                        <div>
                          {i18next.t('subText46', 'Free trial subscription end date')}: {transformLocaleDate(props.subscription.trial_end * 1000)}
                        </div>
                      )
                    : (
                        <div>
                          ({i18next.t('subText47', 'Payment failed date')}: {transformLocaleDate(props.subscription.current_period_start * 1000)})
                        </div>
                      )
                  }
                  
                </span>
              )
            : props.subscription.status
          }
        </p>

        {props.subscription.status === 'trialing'
          ? <div>
              <div>
                {/* Trial End Date: {new Date(props.subscription.trial_end * 1000).toLocaleString()} */}
                {i18next.t('subText20', 'Trial End Date')}: {new Date(props.subscription.trial_end * 1000).toLocaleString()}
              </div>
            {/* <div>trial-to-sub</div> */}
            <Link to={{
                pathname: '/subscription/prices-trial-upgrade', 
                state: {
                  subscription: props.subscription.id,
                  trialUpgrade: true 
                }
              }}
            >
              {/* Start Subscription */}
              {i18next.t('subText21', 'Start Subscription')}
            </Link>
            </div>
          : null
        }

        
        {props.subscription.status !== 'past_due' && props.subscription.status !== 'trialing'
          && (
              <p>
                {/* Next Payment date:{" "} */}
                {i18next.t('subText7', 'Next Payment date')}:{" "}
                {/* {new Date(props.subscription.current_period_end * 1000).toLocaleDateString()} */}
                {transformLocaleDate(props.subscription.current_period_end * 1000)}
              </p> 
              )
        }
        
        <p>
          {/* Start date:{" "} */}
          {i18next.t('subText8', 'Start date')}:{" "}
          {/* {new Date(props.subscription.created * 1000).toLocaleDateString()} */}
          {transformLocaleDate(props.subscription.created * 1000)}
        </p>


        {/* <Link to={{pathname: '/change-plan', state: {subscription: props.subscription.id }}}>Change plan</Link><br /> */}
        {/* <Link
          to={{
            pathname: "/cancel",
            state: { 
              subscription: props.subscription.id, 
              data: props.subscription,
              priceData: priceData,
            },
          }}
        >
          Cancel
        </Link> */}
        <p>
        {props.subscription.status === 'active' 
          || props.subscription.status === 'past_due'
          ? (
              <Link
                to={{
                  pathname: "/subscription/cancel",
                  // state: { 
                  //   subscription: props.subscription.id, 
                  //   data: props.subscription,
                  //   priceData: priceData,
                  // },
                }}
                state={{
                  subscription: props.subscription.id, 
                  data: props.subscription,
                  priceData: priceData,
                }}
              >
                {/* Cancel Subscription */}
                {i18next.t('subText10', 'Cancel Subscription')}
              </Link>
            )
          : null
        }
        </p>

        <p>
          {props.subscription.status === 'active' 
            && state.stripeData.prices 
            && state.stripeData.prices.length > 1
              ? (
                  <Link to={{
                      pathname: '/subscription/change-plan', 
                      // state: { 
                      //   subscription: props.subscription.id,
                      //   data: props.subscription,
                      //   priceData: priceData,
                      // }
                    }}
                    state={{ 
                      subscription: props.subscription.id,
                      data: props.subscription,
                      priceData: priceData,
                    }}
                  >
                    {/* Change Type */}
                    {i18next.t('subText11', 'Change Type')}
                  </Link>
                )
              : null
          }
        </p> 

        <p>
          {/* Latest invoice: {props.subscription.latest_invoice} */}
          {/* <span style={{wordBreak:"break-all"}}>
            {latestInvoice?.hosted_invoice_url}
            </span> */}
            {/* <br /> */}
            {latestInvoice && 
              <a 
                href={latestInvoice.hosted_invoice_url} 
                target="_blank" rel="noopener noreferrer"
              >
                {/* Link to Invoice */}
                {i18next.t('subText9', 'Link to Invoice')}
              </a>
            }
        </p>

        {/* {upcomingInvoice ?
          <p>Upcoming invoice number: {upcomingInvoice.number}
          <br />
          created: {new Date(upcomingInvoice.created * 1000).toString()}
          <br/>
          next-payment-attempt: {new Date(upcomingInvoice.next_payment_attempt * 1000).toString()}
        </p>
        : null
        }
        
        <p>customerId: {localStorage.getItem('customerId')}</p>
        <p>subscriptionId: {props.subscription.id}</p> */}
      </section>
    </Fragment>
  );
}

export default AccountSubscription2;
