import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_ADMINALL_BUCKETOBJECTS: (state, payload) => {
      return { 
        // ...state,
        adminStore : {
          ...state.adminStore,
          adminAllBucketObjects: payload, 
        }
      };
    },
    SET_ADMIN_SELECTEDBUCKET: (state, payload) => {
      return { 
        // ...state,
        adminStore : {
          ...state.adminStore,
          adminSelectedBucket: payload, 
        }
      };
    },
    SET_BUCKETS: (state, payload) => {
      return { 
        // ...state,
        adminStore : {
          ...state.adminStore,
          buckets: payload, 
        }
      };
    },

  };

  initStore(actions, {
    adminStore: {
      adminAllBucketObjects: [],
      adminSelectedBucket: '',
      buckets: [],
    }
    // lastPage: 0,
    // notification: {
    //   status: null,
    //   title: null,
    //   message: null,
    // },
    // i18n : undefined
  });
};

export default configureStore;
