
export const getAllBucketObjects = async (url, token, bucket, prefix) => {
  try {

    const result = await fetch(url + `/admin-bucket/all-objects?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const getAllBucketPrefixes = async (url, token, bucket, prefix) => {
  try {

    const result = await fetch(url + `/admin-bucket/all-prefixes?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getBucketCommonPrefixes = async (url, token, bucket) => {
  try {
    const result = await fetch(url + `/admin-bucket/common-prefixes?bucket=${bucket}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    // console.log(result);
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getBucketFolderContents = async (url, token, bucket, prefix) => {
  try {
    const result = await fetch(url + `/admin-bucket/folder-top-level-contents?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    // console.log(result);
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const getObjectSignedUrl = async (url, token, bucket, key, expires) => {
  try {
    //// expires in second 1hour => 3600
    const result = await fetch(url + `/admin-bucket/signed-url?bucket=${bucket}&key=${key}&expires=${expires}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const deleteBucketObjects = async (url, token, bucket, keys) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/admin-bucket/delete-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        keys: keys,
        bucket: bucket,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const copyBucketObjects = async (url, token, bucket, inputKeys, targetPrefix) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/admin-bucket/copy-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetPrefix: targetPrefix,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const moveDeleteBucketObjects = async (url, token, bucket, inputKeys, targetPrefix) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/admin-bucket/move-delete-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetPrefix: targetPrefix,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const createFolder = async (url, token, key, bucket) => {
  try {
    const result = await fetch(url + `/admin-bucket/create-folder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        // key: currentPrefix + folderName + '/',
        key: key,
        bucket: bucket,
      })
    });

    
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const deleteFolders = async (url, token, bucket, prefixes) => {
  try {
    const result = await fetch(url + `/admin-bucket/delete-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        keys: prefixes,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const copyFolders = async (url, token, bucket, inputKeys, targetKey) => {
  try {
    const result = await fetch(url + `/admin-bucket/copy-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetKey: targetKey,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }
  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const moveDeleteFolders = async (url, token, bucket, inputKeys, targetKey) => {
  try {
    const result = await fetch(url + `/admin-bucket/move-delete-folders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        inputKeys: inputKeys,
        targetKey: targetKey,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }
  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export const changeFilename = async (url, token, bucket, oldKey, newKey, objectList) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/admin-bucket/change-filename`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        oldKey: oldKey,
        newKey: newKey,
        objectList: objectList,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};

export function openInNewTab(url) {
  window.open(url, '_blank', 'noopener','noreferrer').focus();
}

export function getPrefixFromKey(key) {
  const objectArray = key.split('/');

  let prefix = '';

  if (objectArray.length >= 2) {
    objectArray.pop();
    prefix = objectArray.join('/') + '/';
  }

  return prefix;
}


export function createSearchedTopLevels(searchSelectedItem, topLevels) {
  if (searchSelectedItem.Key.endsWith('/')) {
    return null;
  }
  
  const withoutSearh = topLevels.filter(object => {
    return object.Key !== searchSelectedItem.Key
  });

  const searchTopList = [searchSelectedItem].concat(withoutSearh);

  return searchTopList;
}