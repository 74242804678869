import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import Backdrop from '../../Backdrop/Backdrop';
import NavUser from '../NavUser/NavUser';
import ToggleNavButton from '../../Navigation/ToggleNav/ToggleNavButton/ToggleNavButton';
import ToggleNav from '../../Navigation/ToggleNav/ToggleNav/ToggleNav';

import { useStore } from '../../../hook-store/store';

// import { marks } from '../../../utils/marks';
import classes from './MainNav.module.css';

import ObjectSearch from '../../BucketComponents/ObjectSearch/ObjectSearch';

function MainNav(props) {
  // console.log('Layout.js-props', props);
  const location = useLocation();

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  const showToggleNav = store.uiStore.showToggleNav;
  const gLoading = store.uiStore.gLoading;


  let isObjectsPage = false;

  if (location.pathname === '/') {
    isObjectsPage = true;
  }

  return (
    <Fragment>
      <div className={classes.mainNav}>
        <ToggleNav />
        <ToggleNavButton /> 
        {showToggleNav && (
          <Backdrop 
            zIndex={'190'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          //  backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              if (!gLoading) {
                dispatch('SET_SHOWTOGGLENAV', false); 
              }
            }}
          />
        )}
        {/* <div>{userEmail}</div> */}
        
        {isObjectsPage && (
          <ObjectSearch 
            setSearchSelectedItem={() => {}}
          />
        )}

        <NavUser />
   
      </div>
    </Fragment>
  );
}

export default MainNav;
