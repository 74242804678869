import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Loader from '../../Loader/Loader';
import AdminObjectListItem from './AdminObjectListItem';
import AdminObjectSearch from './AdminObjectSearch';
import { useStore } from '../../../hook-store/store';
import { BACKEND_URL } from '../../../App';

import * as adminBucketObjectUtils from '../../../utils/admin-bucket/admin-bucket-object-util';

import classes from './AdminBucketObjectList.module.css';

function AdminBucketObjectList(props) {

  const [store, dispatch] = useStore();
  const gLoading = store.uiStore.gLoading;
  const adminSelectedBucket = store.adminStore.adminSelectedBucket;
  const adminAllBucketObjects = store.adminStore.adminAllBucketObjects;

  const [maxLenList, setMaxLenList] = useState([]);
  const [selectedNum, setSelectedNum] = useState(1);
  const [adminPrefixList, setAdminPrefixList] = useState([]);
  const [selectedAdminPrefix, setSelectedAdminPrefix] = useState('');

  const [selectedKey, setSelectedKey] = useState('');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [searchSelect, setSearchSelect] = useState('');
  useEffect(() => {
    if (adminAllBucketObjects.length > 0) {
      let maxLength = 0;
      const prefixList = [];

      for (const object of adminAllBucketObjects) {
        const splitLength = object.Key.split('/').length;
        if (splitLength > maxLength) {
          maxLength = splitLength;
        }

        const keyList = object.Key.split('/');
        keyList.pop();
        prefixList.push(keyList.join('/') + '/');

        // if (object.Key.endsWith('/')) {
        //   prefixList.push(object.Key);
        // } else {
        //   const keyList = object.Key.split('/');
        //   keyList.pop();
        //   if (keyList.length >= 1) {
        //     const fName = keyList.join('/') + '/';
        //     prefixList.push(fName);
        //   }
        // }

        // console.log('prefixList', prefixList);
        // console.log('uniq', uniqList);
        const uniqList = _.uniq(prefixList);
        setAdminPrefixList(uniqList);
        // console.log('maxLen', maxLength, splitLength);
      }

      const numList = [];
      for (let i=0; i<maxLength; i++) {
        numList.push(i);
      }
      
      setMaxLenList(numList);

    }
  },[adminAllBucketObjects]);

  useEffect(() => {
    if (adminSelectedBucket) {
      adminGetBucketObjectsHandler();
    }
  },[adminSelectedBucket]);

  useEffect(() => {
    setSelectedKeys([]);
  },[selectedAdminPrefix]);

  const adminGetBucketObjectsHandler = async () => {
    try {
      dispatch('SET_GLOADING', true);

      const result = await adminBucketObjectUtils.getAllBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        adminSelectedBucket,
        '',
      );
  
      console.log(result);
  
      if (result.data) {
        dispatch('SET_ADMINALL_BUCKETOBJECTS', result.data.objectList);
      }

      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }

  }

  const showActionsHandler = (key) => {
    if (showAction && key === selectedKey) {
      setShowAction(false);
    } else {
      setShowAction(true);
    }

    setSelectedKey(key);
  };

  const setSelectedAdminPrefixHandler = (prefix) => {
    if (prefix === '/') {
      setSelectedAdminPrefix('');
    } else {
      setSelectedAdminPrefix(prefix);
    }
  }

  const setSelectedKeysHandler = (key) => {
    const isInList = selectedKeys.find(ke => ke === key);

    if (!isInList) {
      const addedList = selectedKeys.concat(key);
      setSelectedKeys(addedList);
    }
    
    if (isInList) {
      const deletedList = selectedKeys.filter(ky => ky !== key);
      setSelectedKeys(deletedList);
    } 
    else {

    }

    setSelectedKey(key);
  }

  let prefixesBody;

  if (adminPrefixList.length > 0) {
    prefixesBody = (
      <ul style={{display:"flex", gap:"0.2rem", flexWrap:"wrap"}}>
        {adminPrefixList.map(prefix => {
          return (
            <li>
              <button
                onClick={() => { 
                  setSelectedAdminPrefixHandler(prefix); 
                }}
              >
                {prefix}
              </button>
            </li>
          );
        })}
      </ul>
    );
  }

  // let numButtonsBody
  
  // if (maxLenList.length > 0) {
  //   numButtonsBody = (
  //     <ul style={{display:"flex", gap:"0.5rem"}}>
  //       {maxLenList.map(num => {
  //       return (
  //         <li key={num}>
  //           <button
  //             onClick={() => { setSelectedNum(num + 1); }}
  //           >
  //             {num + 1}
  //           </button>
  //         </li>
  //       )
  //     })}
  //     </ul>
  //   )
  // }


  let AdminObjectsBody;

  // if (adminAllBucketObjects.length > 0) {
  //   AdminObjectsBody = (
  //     <ul>
  //       {adminAllBucketObjects.map((object, index) => {

  //         if (object.Key.split('/').length === selectedNum) {
  //           const fileName = object.Key.split('/')[object.Key.split('/').length -1];
  //           const timeEndIndex = fileName.indexOf('Z');
  //           return (
  //             <li key={object.Key}>
  //               {/* {object.Key.slice(timeEndIndex + 2)} */}
  //               {object.Key}
  //             </li>
  //           )
  //         }
  //       })}
  //     </ul>
  //   )
  // }

  if (adminAllBucketObjects.length > 0) {
    let filteredList;

    if (selectedAdminPrefix) {
      filteredList = adminAllBucketObjects.filter(object => {
        return object.Key.startsWith(selectedAdminPrefix) && 
          object.Key.split('/').length >= selectedAdminPrefix.split('/').length; 
      })
    } else {
      filteredList = adminAllBucketObjects;
    }

    AdminObjectsBody = (
      <ul>
        <div>key-num: {filteredList.length}</div>
        <button
          onClick={() => { setSelectedKeys([]); }}
        >
          de-select-all
        </button>
        <button
          onClick={() => { setShowAction(true); }}
        >
          show-actions
        </button>
        
      {selectedKeys.length > 0 && showAction && (
        <div style={{ border: "1px solid red" }}>
          actions-for-object
          <div>ac-1</div>
          <div>ac-2</div>
          <div>ac-3</div>
        </div>
      )}

        {filteredList.map((object, index) => {
          return (
            <li key={object.Key}>
              <AdminObjectListItem 
                object={object}
                selectedKey={selectedKey}
                selectedKeys={selectedKeys}
                setSelectedKeysHandler={setSelectedKeysHandler}
              />
            </li>
          );

        })}
      </ul>
    )
  }

  return (
    <Fragment>
      {gLoading && <Loader/>}
      <div className={classes.bucketAdminContent}
        style={{paddingTop:"5rem"}}
      >
        <Link to="/admin">admin-page-link</Link>
        <div>
          admin-bucket-object-list
        </div>
        <div>
          selected-bucket: <strong>{adminSelectedBucket}</strong>
          {', '}
          {adminAllBucketObjects.length} key-num
        </div>

        <AdminObjectSearch 
          setSearchSelect={setSearchSelect}
        />
        {searchSelect && (
          <div>{searchSelect.Key}</div>
        )}
        <button 
          disabled={gLoading}
          onClick={() => {
            adminGetBucketObjectsHandler();
          }}
        >
          get-bucket-objects
        </button>
      </div>

      {/* <div>{numButtonsBody}</div> */}
      <div>{prefixesBody}</div>
      <div>{AdminObjectsBody}</div>

    </Fragment>
  );
}

export default AdminBucketObjectList;
