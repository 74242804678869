export const confirmModalStyle = {
  position: 'fixed',
  top: '10%',
  left: '10%',
  right: '10%',
  zIndex: '200',

  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  padding: '0.5rem',
  border: `0.5px solid var(--color-gray-200)`,
  borderRadius: '4px',
};

export const confirmModalWideStyle = {
  position: 'fixed',
  top: '10%',
  left: '0',
  right: '0',
  maxWidth: '40rem',
  margin: '0 auto',
  zIndex: '200',

  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  padding: '0.5rem',
  border: `0.5px solid var(--color-gray-200)`,
  borderRadius: '4px',
};

export const confirmModalMobileStyle = {
  position: 'fixed',
  // width: '90%',
  // height: '50vh',
  // minheight: '50vh',
  maxHeight: '85vh',
  bottom: '10%',
  left: '5%',
  right: '5%',
  margin: '0 auto',
  zIndex: '200',

  overflow: 'auto',
  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  padding: '0.5rem',
  border: `0.5px solid var(--color-gray-200)`,
  borderRadius: '4px',
};