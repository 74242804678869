import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// import Loader from '../../Loader/Loader';
import Backdrop from '../../Backdrop/Backdrop';
import Modal from '../../Modal/Modal';
import { useStore } from '../../../hook-store/store';

// import { BACKEND_URL } from '../../../App';
import { marks } from '../../../utils/marks';

import classes from './MeetingInfo.module.css';

function MeetingInfo(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  const remeetLink = (
    <a href={`https://remeet.watakura.xyz/new-room-from-above-link`}
      target="_blank" rel="noopener noreferrer"
    >
      watakura REMEET
    </a>
  );

  const bookRemeetLink = (
    <a href={`https://bookremeet.spaceeight.net`}
      target="_blank" rel="noopener noreferrer"
    >
      reservation page
    </a>
  );

  let meetingInfoBody;

  meetingInfoBody = (
    <div>
      <div className={classes.meetingInfoTitle}>
        watakura REMEET video application
      </div>
      <p>
        {remeetLink} 
        {' '}
        {t('remeetInfo01', 'is a simple video meeting and talk application.')}
        {' '}
        {t('remeetInfo02', 'In addition to video talk, it contains text chat, screen sharing, file sharing')}.
      </p>
      <p>
        REMEET
        {' '}
        {t('remeetInfo03', 'users can communicate with multiple people at the same time.')} 
        {' '}
        {t('remeetInfo04', 'That is suitable for various situations such as talking with remote people, remote work, and remote meeting.')}
        {' '}
        {t('remeetInfo05', 'More information is found')} 
        {' '}at {bookRemeetLink}.
      </p>
      <p>
        {t('remeetInfo06', 'Start your next meeting with collaborator')} 
        {' '}
        at {bookRemeetLink}.
      </p>
    </div>
  );

  return (
    <Fragment>
          <Backdrop 
            onCancel={() => {
              if (!isLoading) {
                dispatch('SET_SHOWMEETINGINFO', false);
              }
            }}
          />
          <Modal
            hideButtons={true}
            onClose={() => {
              if (!isLoading) {
                dispatch('SET_SHOWMEETINGINFO', false);
              }
            }}
          >
            {meetingInfoBody}
          </Modal>
    </Fragment>
  );
}

export default MeetingInfo;
