import classes from './Backdrop.module.css'

function Backdrop(props) {
  const { zIndex, backgroundColor } = props;

  let backdropStyle = null;

  if (zIndex) {
    backdropStyle = {
      zIndex: `${zIndex}`
    }
  }

  if (backgroundColor) {
    backdropStyle = {
      ...backdropStyle,
      backgroundColor: backgroundColor,
    }
  }

  return (
    <div className={classes.backdrop}
      // style={zIndex ? {zIndex: `${zIndex}`} : null}
      style={backdropStyle}
      onClick={props.onCancel}
    >
    </div>
  );
}

export default Backdrop;