import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import numeral from 'numeral';
// import axios from 'axios';

// import Backdrop from '../../Backdrop/Backdrop';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalConfirm from '../../../Modal/ModalConfirm';
import SizesContent from '../../SizesContent/SizesContent';
import SizesSummary from '../../SizesContent/SizesSummary';

import { useStore } from '../../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

// import { BACKEND_URL } from '../../../App';

import { marks } from '../../../../utils/marks';
// import classes from './TrashMenuForWide.module.css';
import classes from '../../BucketObject/BucketObjectMenu.module.css';

function TrashMenuForWide(props) {
  const {
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const {
    bucketName, 
    bucketSizeGB,
    totalSizeGB,
    limitGB,
   } = store.bucketObjectStore;
  // const { subscriptions } = store.stripeData;
  const { gLoading, isAuth } = store.uiStore;


  // const [clickedObjectKey, setClickedObjectKey] = useState('');
 
  // const [modalPositions, setModalPositions] = useState({ 
  //   top: null, 
  //   left: null,
  //   right: null,
  //   bottom: null,
  //   heightLimit: 300,
  // });

  // const [showObjectControl, setShowObjectControl] = useState(false);
  const [showDropArea, setShowDropArea] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showSizesSummary, setShowSizesSummary] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   const activeSubscription = subscriptions.find(subscription => {
  //     return subscription.status === 'active';
  //   });
  
  //   if (activeSubscription) {
  //     const subLimitGB = Number(activeSubscription.metadata.storeSizeGB);
      
  //     if (subLimitGB > 0) {
  //       dispatch('SET_LIMITGB', subLimitGB);
  //     }
  //   }
  //   else {
  //     dispatch('SET_LIMITGB', freeSizeGB);
  //   }
  // },[subscriptions]);

  // const showObjectControlHandler = (value) => {
  //   // setShowObjectControl(value);
  //   dispatch('SET_SHOWOBJECTCONTROL', value);

  //   const targetEl = document.getElementById(`object-control`);
  //   const targetRect = targetEl.getBoundingClientRect();

  //   console.log(targetEl);
  //   console.log(targetRect);

  //   console.log(targetRect.bottom, window.innerHeight - targetRect.bottom, window.innerHeight)
    
  //   // const heightLimit = 300;

  //   setModalPositions({
  //     ...modalPositions,
  //     top: targetRect.bottom,
  //     heightLimit: window.innerHeight - targetRect.bottom,
  //   })

  // };


  if (!isAuth || !bucketName || window.location.pathname !== '/') {
    return null;
  }

  return (
    <Fragment>
   

      
      <div className={classes.objectControlListItem}
        onClick={() => { 
          if (!isLoading) {
            dispatch('SET_DISPLAYTABLE', ''); 
            // showObjectControlHandler(false);
          }
        }}
      >
        {marks.tableMark} {t('objectControl.05', 'files')}
      </div>

      <div className={classes.objectControlListItem}>
        <SizesContent 
          setShowSizesSummary={setShowSizesSummary}
        />
      </div>

      {showSizesSummary && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { 
              if (!isLoading) {
                setShowSizesSummary(false);
              }
            }}
          />
        <ModalConfirm 
        hideButtons={true}
        onClose={() => { 
          if (!isLoading) {
            setShowSizesSummary(false);
          } 
        }}
        >
          <SizesSummary />
        </ModalConfirm>
        </div>
      )}


      

    </Fragment>
  );
}

export default TrashMenuForWide;
