import React from "react";
import { Fragment, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { useStore } from "../../../hook-store/store";

import { BACKEND_URL} from '../../../App';

function AccountSubscription(props) {
  // console.log("accountSubscripiton.js-props", props);
  const lsCustomerEmail = localStorage.getItem('customerEmail');
  const lsToken = localStorage.getItem('token');

  const [state, dispatch] = useStore();
  // console.log(state);

  const [latestInvoice, setLatestInvoice] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();

  // const priceData = state.stripeData.prices.find((ele) => {
  //   return ele.lookup_key === props.subscription.items.data[0].price.lookup_key;
  // });


  const priceData = state.stripeData.prices.find((ele) => {
    // console.log(ele.id);
    return ele.id === props.subscription.plan.id;
  });
  // console.log(props.subscription, priceData);
  // console.log(props.subscription.latest_invoice)


  useEffect(() => {

    const retrieveLatestInvoice = async (invoiceId, token, email) => {
      const { invoice, error } = await fetch(BACKEND_URL + '/invoice/latest-invoice', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoiceId: invoiceId,
          email: email,
        }),
      })
      .then(r => r.json());

      if (error) {
        // console.log(error)
      }
      if (invoice) {
        // console.log(invoice);
        setLatestInvoice(invoice);
      }
    }


    const retrieveUpcomingInvoice = async (customerId, subscriptionId, priceId, token, email) => {
      const { invoice, error } = await fetch(BACKEND_URL + '/invoice/upcoming-invoice', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
          priceId: priceId,
          email,
        }),
      })
      .then(r => r.json());

      if (error) {
        // console.log(error)
      }
      if (invoice) {
        // console.log(invoice);
        setUpcomingInvoice(invoice);
      }
    }

    // console.log(props.subscription, priceData)
    retrieveLatestInvoice(
      props.subscription.latest_invoice,
      lsToken,
      lsCustomerEmail
      );

    if (priceData) {
      retrieveUpcomingInvoice(
        localStorage.getItem('customerId'),
        props.subscription.id,
        priceData.id,
        lsToken,
        lsCustomerEmail
      );
    }

  },[]);

  return (
    <Fragment>
      <section>
        <hr />
        <h4>
          <a
            href={`https://dashboard.stripe.com/test/subscriptions/${props.subscription.id}`}
          >
            {props.subscription.id}
          </a>
        </h4>

        <p>
          lookup_key: {priceData && priceData.lookup_key}
          <br />
          product-name: {priceData && priceData.product.name}
          <br />
          unit-amount:{" "}
          {priceData &&
            `${
              priceData.unit_amount / 100
            } ${priceData.currency.toUpperCase()} / ${
              priceData.recurring.interval
            }`}
            <br />
            priceId: {priceData && priceData.id}
        </p>

        <p>Status: {props.subscription.status}</p>

        <p>
          Card last4: {props.subscription.default_payment_method?.card?.last4}
        </p>

        <p>
          Current period start:{" "}
          {new Date(props.subscription.current_period_start * 1000).toString()}
        </p>
        <p>
          Current period end (next payment):{" "}
          {new Date(props.subscription.current_period_end * 1000).toString()}
        </p>
        <p>
          Created:{" "}
          {new Date(props.subscription.created * 1000).toString()}
        </p>
        <p>
          Latest invoice: {props.subscription.latest_invoice}
          {/* <span style={{wordBreak:"break-all"}}>
            {latestInvoice?.hosted_invoice_url}
            </span> */}
            <br />
            {latestInvoice && 
              <a href={latestInvoice.hosted_invoice_url}>link-invoice</a>
            }
        </p>

        {/* <Link to={{pathname: '/change-plan', state: {subscription: props.subscription.id }}}>Change plan</Link><br /> */}
        {/* <Link
          to={{
            pathname: "/cancel",
            state: { 
              subscription: props.subscription.id, 
              data: props.subscription,
              priceData: priceData,
            },
          }}
        >
          Cancel
        </Link> */}
        {props.subscription.status === 'active' 
          || props.subscription.status === 'past_due'
          ? (
              <Link
                to={{
                  pathname: "/subscription/cancel",
                  state: { 
                    subscription: props.subscription.id, 
                    data: props.subscription,
                    priceData: priceData,
                  },
                }}
              >
                Cancel
              </Link>
            )
          : null
        }

        <br /> 
        {props.subscription.status === 'active' 
            ? (
                <Link to={{
                    pathname: '/change-plan', 
                    state: { subscription: props.subscription.id }
                  }}
                >
                  Change plan
                </Link>
              )
            : null
        }

        <br />
        {props.subscription.status === 'trialing'
          ? <div>
              traial-end: {new Date(props.subscription.trial_end * 1000).toString()}
            {/* <div>trial-to-sub</div> */}
            </div>
          : null
        }

        {upcomingInvoice ?
          <p>Upcoming invoice number: {upcomingInvoice.number}
          <br />
          created: {new Date(upcomingInvoice.created * 1000).toString()}
          <br/>
          next-payment-attempt: {new Date(upcomingInvoice.next_payment_attempt * 1000).toString()}
        </p>
        : null
        }

        <p>metadata: {JSON.stringify(props.subscription.metadata)}</p>
        
        <p>customerId: {localStorage.getItem('customerId')}</p>
        <p>subscriptionId: {props.subscription.id}</p>
      </section>
    </Fragment>
  );
}

export default AccountSubscription;
