import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ModalConfirm from '../../Modal/ModalConfirm';
import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
import { 
  getObjectMetadata,
  addTrashMetadata,
} from '../../../utils/bucket/object-metadata-util';


import { BACKEND_URL } from '../../../App';

function SelectedObjectsAddTrashboxMeta(props) {
  // const {  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  // const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  // const topLevels = store.bucketObjectStore.topLevels;

  const {
    bucketName,
    allBucketObjects, 
    selectedFileKeys,
    movePrefixes,

   } = store.bucketObjectStore;

   const { allObjectsMetaList } = store.objectMetadataStore;

   const { gLoading } = store.uiStore;

   console.log(selectedFileKeys, movePrefixes)

  // const [showFoldersModal, setShowFoldersModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const addSelectedTrashboxMetaHandler = async(bucketName, keys, prefixes, allBucketObjects, allObjectsMetaList) => {
    try {

      dispatch('SET_GLOADING', true);
      
      const selectedObjects = getAddTrashboxMetaObjects(
        keys, 
        prefixes, 
        allBucketObjects,
      );

      console.log(selectedObjects);
      // throw new Error('error-error');

      let addMetaResults = [];
      let forMetaUpdateList = allObjectsMetaList;

      const nowTime = Date.now();

      for (const object of selectedObjects) {

        let oldMetadata;
          
        const objectMeta = await getObjectMetadata(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          object.Key,
        );
        
        if (objectMeta) {
          oldMetadata = objectMeta.data;
        }
    
  
      console.log(oldMetadata);
  
      if (oldMetadata) {
        console.log('oldMetadata', oldMetadata);
        const parsedOldMeta = JSON.parse(oldMetadata.objectMeta.Metadata.metastring);
        const updateMeta = {
          ...parsedOldMeta,
          location: 'trashbox',
          trashMoveTime: nowTime,
        };
  
        console.log(updateMeta);
  
        let storePath = bucketObjectUtils.getPrefixFromKey(object.Key);
          
        if (object.Key.endsWith('/')) {
          const separateList = object.Key.split('/');
          separateList.pop();
          separateList.pop();

          
          if (separateList.length > 0) {
            storePath = separateList.join('/') + '/';
          } else {
            storePath = '';
          }
        } 

        const resData = await addTrashMetadata(
            BACKEND_URL,
            localStorage.getItem('token'),
            bucketName,
            storePath,
            object.Key,
            updateMeta,
            dispatch,
          );
        
          console.log(resData);

          if (resData.data) {
            addMetaResults =  addMetaResults.concat(resData.data);
            
            const noOldObjectMetaList = forMetaUpdateList.filter(element => {
              return element.Key !== object.Key;
            });
          
            forMetaUpdateList = noOldObjectMetaList.concat({
              Key: resData.data.Key,
              objectMeta: resData.data.objectMeta,
            });

            dispatch('SET_ALLOBJECTSMETALIST', forMetaUpdateList);
          }

        }
        
      }

      dispatch('SET_OBJECTACTION', {
        actionType: 'update-metadata',
        delete: true,
        add: true,
        result: addMetaResults,
      });
        

      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'folder moved to trash box',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'folder move failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  const getAddTrashboxMetaObjects = (keys, prefixes, allBucketObjects) => {
    let objectList = [];

    for (const key of keys) {
      const keyObject = allBucketObjects.find(object => {
        return object.Key === key;
      });

      objectList = objectList.concat([keyObject]);
    }

    for (const prefix of prefixes) {
      const folderObjects = bucketObjectUtils.getFolderObjectsFromAllList(prefix, allBucketObjects)

      objectList = objectList.concat(folderObjects);
    }

    // console.log(objectList);
    return objectList;
  };




  let selectedObjectsDeleteBody;

  selectedObjectsDeleteBody = (
    <div>
      <ModalConfirm 
        onClose={() => { 
          dispatch('SET_OBJECTS_ACTIONTYPE', '');
        }}
        onAccept={() => { 
          addSelectedTrashboxMetaHandler(
            bucketName,
            selectedFileKeys,
            movePrefixes,
            allBucketObjects,
            allObjectsMetaList,
          );
        }}
        isLoading={isLoading}
      >
        {t('trash.13', 'move to trash box')} ?
        {/* {t('selectedObject.02', 'delete')} ?  */}
      </ModalConfirm>
      
      {/* <div>
        <button 
          disabled={isLoading}
          onClick={() => { 
            if (!isLoading) {
              setShowDeleteModal(true);
            }
          }}
        >
          delete-selected-files-folders
        </button>
      </div> */}
    </div>
  );


  
  return (
    <Fragment>
      {selectedObjectsDeleteBody}
    </Fragment>
  );
}

export default SelectedObjectsAddTrashboxMeta;
