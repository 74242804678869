import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AuthRequired from "../../components/Auth/AuthRequired";

import { useStore } from "../../hook-store/store";

import classes from './NotPageFound.module.css';

const NotApisAvailable = (props) => {
  // const { } = props;

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  const { isStripeApi } = store.stripeData;

  useEffect(() => {

  }, []);

  let notServiceAvailableBody;

  notServiceAvailableBody = (
    <div>
      <div>
        Not Service Available Now. Please try again later.
      </div>

      {/* not auth tools links */}
      
      {/* <div>
        <Link to="/image-editor">
          go-to-image-editor-page
        </Link>
      </div> */}
    </div>
  )
  

  if (gLoading) {
    notServiceAvailableBody = (<div></div>);
  }


  return (
    <Fragment>
      <div className="pageContentContainer">
        {notServiceAvailableBody}
      </div>
    </Fragment>
  );
};

export default NotApisAvailable;
