import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';

import AdminBucketSearch from './AdminBucketSearch';
// import AdminBucketObjectList from '../AdminBucketObject/AdminBucketObjectList';
import Loader from '../../Loader/Loader';
import * as bucketUtils from '../../../utils/bucket/bucket-util';

import { useStore } from '../../../hook-store/store';
import { BACKEND_URL } from '../../../App';

import classes from './AdminBucket.module.css';

function BucketAdmin(props) {

  const [store, dispatch] = useStore();
  const adminSelectedBucket = store.adminStore.adminSelectedBucket;
  const buckets = store.adminStore.buckets;
  const gLoading = store.uiStore.gLoading;


  // const [buckets, setBuckets] = useState([]);
  const [bucketInput, setBucketInput] = useState('');
  
  const [searchSelect, setSearchSelect] = useState();

  useEffect(() => {
    if (buckets.length === 0) {
      getBuckets();
    }
    // getUserBucketHandler(localStorage.getItem('userId'));
  },[buckets]);

  // useEffect(() => {
  //   dispatch('SET_BUCKETNAME', 'test-access-bucket');
  // },[]);

  const getBuckets = async () => {
    try {
      dispatch('SET_GLOADING', true);
      const buckets = await bucketUtils.getBuckets(BACKEND_URL, localStorage.getItem('token'));

      console.log(buckets);
      // setBuckets(buckets.data.Buckets);
      dispatch('SET_BUCKETS', buckets.data.Buckets);

      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }
  };

  const bucketInputHandler = (event) => {
    // console.log(event.target.value);
    setBucketInput(event.target.value);
  }

  const createBucketHandler = async (bucketname) => {
    try {
      const result = await bucketUtils.createBucket(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketname,
      );
      console.log(result);

    } catch(err) {
      console.log(err);
    }
  };


  const getUserBucketHandler = async (userId) => {
    try {
      dispatch('SET_GLOADING', true);

      const bucket = await bucketUtils.getUserBucket(
        BACKEND_URL, 
        localStorage.getItem('token'),
        userId,
      );

      console.log(bucket);

      if (bucket === 'not-found') {
        await createBucketHandler(userId);
        getUserBucketHandler(userId);
      }

      dispatch('SET_BUCKETNAME', bucket.data.Name);

      dispatch('SET_GLOADING', false);
      // setBuckets(buckets.data.Buckets);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }
  };


  let bucketListBody;
  

  if (buckets.length > 0) {

    if (!searchSelect) {
      bucketListBody = (
        <ul>{buckets.map((bucket, index) => {
          return (
            <div key={index}>
              <div>
                <strong>{bucket.Name}</strong>
                <button 
                  onClick={() => {
                    // console.log(bucket.Name);
                    dispatch('SET_ADMIN_SELECTEDBUCKET', bucket.Name);
                  }}
                >
                  access-button
                </button>
              </div>
              <div>{bucket.CreationDate}</div>
              <hr/>
            </div>
          );
        })}</ul>
      );
    } 
    else {
      bucketListBody = (
        <>
          <div style={{border:'1px solid gray'}}>
            <div>search-select-item</div>
            <div>
              <strong>{searchSelect.Name}</strong>
              <button 
                onClick={() => {
                  dispatch('SET_ADMIN_SELECTEDBUCKET', searchSelect.Name);
                }}
              >
                access-button
              </button>
            </div>
            <div>{searchSelect.CreationDate}</div>
            <hr/>
          </div>
          <ul>
            {buckets.map((bucket, index) => {
              return (
                <div key={index}>
                  <div>
                    <strong>{bucket.Name}</strong>
                    <button 
                      onClick={() => {
                        dispatch('SET_ADMIN_SELECTEDBUCKET', bucket.Name);
                      }}
                    >
                      access-button
                    </button>
                  </div>
                  <div>{bucket.CreationDate}</div>
                  <hr/>
                </div>
              );
            })}
          </ul>
        </>
      );
    }
  }

  return (
    <Fragment>
      {gLoading && <Loader/>}
      <div className={classes.bucketAdminContent}>
        <AdminBucketSearch 
          buckets={buckets}
          setSearchSelect={setSearchSelect}
        />

        bucket-admin-content
        
        <div style={{position:"fixed", top:"3rem", right: "2rem"}}>
          <div>selected-bucket: <strong>{adminSelectedBucket}</strong></div>
          <Link to="/admin/object-list">
            <button disabled={!adminSelectedBucket}>
              link-to-object-list
            </button>
          </Link>
        </div>
        
        <div>{bucketListBody}</div>
      </div>

      {/* <AdminBucketObjectList /> */}

      {/* <label htmlFor="bucket" >bucket-name</label>
      <input type='text' name="bucket" onChange={bucketInputHandler}/>
      <button onClick={() => {createBucketHandler(bucketInput); }}>create-bucket</button> */}
      
      {/* <input type="file" name="file" onChange={onChangeHandler}/>
      <button type="button" class="btn btn-success btn-block" onClick={onClickHandler}>Upload</button>  */}
    </Fragment>
  );
}

export default BucketAdmin;
