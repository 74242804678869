import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_SHAREOBJECTS: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          shareObjects: payload, 
        }
      };
    },
    SET_SHAREOWNERS: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          shareOwners: payload, 
        }
      };
    },
    SET_SHARINGUSERS: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          sharingUsers: payload, 
        }
      };
    },
    // SET_SHARINGUSERNAMEDATALIST: (state, payload) => {
    //   return { 
    //     // ...state,
    //     shareObjectStore : {
    //       ...state.shareObjectStore,
    //       sharingUserNameDataList: payload, 
    //     }
    //   };
    // },
    SET_SHARINGOBJECTS: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          sharingObjects: payload, 
        }
      };
    },
    SET_SHAREOBJECTLOADING: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          shareObjectLoading: payload, 
        }
      };
    },
    SET_SHARENOTIFICATION: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          shareNotification: payload, 
        }
      };
    },
    SET_SHOWSHARENOTIFICATIONS: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          showShareNotifications: payload, 
        }
      };
    },
    SET_NOTOPENSHARENOTIFICATIONEXIST: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          notOpenShareNotificationExist: payload, 
        }
      };
    },
    SET_SHARENOTIFICATIONOPENTIME: (state, payload) => {
      return { 
        // ...state,
        shareObjectStore : {
          ...state.shareObjectStore,
          shareNotificationOpenTime: payload, 
        }
      };
    },


    // SET_SERVICEBUCKETSINFO: (state, payload) => {
    //   return { 
    //     // ...state,
    //     serviceBucketsStore : {
    //       ...state.serviceBucketsStore,
    //       serviceBucketsInfo: payload, 
    //     }
    //   };
    // },

  };

  initStore(actions, {
    shareObjectStore: {
      shareObjects: [],
      shareOwners: [],
      sharingUsers: [],
      // sharingUserNameDataList: [],
      sharingObjects: [],
      shareObjectLoading: false,
      shareNotification: null,
      showShareNotifications: false,
      notOpenShareNotificationExist: false,
      shareNotificationOpenTime: 0,
      // serviceBucketsInfo: null,
    }
  });
};

export default configureStore;
