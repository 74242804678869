export const getShareObjectSignedUrl = async (
  url,
  token,
  bucket,
  key,
  expires
) => {
  try {
    const result = await fetch(
      url +
        `/share-bucket-object/share-object-signed-url?bucket=${bucket}&key=${key}&expires=${expires}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        // body: JSON.stringify({})
      }
    );

    const resData = await result.json();
    console.log(result, resData);

    if (!result.ok) {
      throw new Error("something wrong");
    }

    return resData;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getShareObjectFile = async (url, token, bucket, key, mimeType) => {
  try {
    const result = await fetch(url + `/share-bucket-object/share-object-file?bucket=${bucket}&key=${key}&mimeType=${mimeType}`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    console.log(result);

    const resData = await result.blob();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
    throw err;
  }
};



export const createSharingObjectList = (objectsMetaList, allBucketObjects) => {
      const sharingObjectList = [];

    for (const oMeta of objectsMetaList) {
      // console.log('object', object)
      const objectMeta = oMeta.objectMeta;

      if (objectMeta && objectMeta.Metadata.metastring) {
        const metadata = objectMeta.Metadata.metastring;
        const parsedMeta = JSON.parse(metadata);
        const shareInfoMeta = parsedMeta.shareInfo;

        const matchObject = allBucketObjects.find(object => {
          return object.Key === oMeta.Key;
        });

        if (shareInfoMeta) {
          if (shareInfoMeta.readIds.length > 0) {

            if (matchObject) {
              sharingObjectList.push({
                ...matchObject,
                objectMeta: objectMeta,
                shareInfo: shareInfoMeta,
                // objectMeta: objectMeta,
              });
            }
          }

          if (shareInfoMeta.writeIds.length > 0) {
            const isInList = sharingObjectList.find(element => {
              return element.Key === objectMeta.Key;
            });
            
            if (matchObject && !isInList) {
              sharingObjectList.push({
                ...matchObject,
                objectMeta: objectMeta,
                shareInfo: shareInfoMeta,
                // objectMeta: objectMeta,
              });
            }
          }
        }
      }

    }

    return sharingObjectList;

};