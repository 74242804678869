// import axios from 'axios';
// import { 
//   getObjectFile,
//   getOriginalFileNameFromKey,
//  } from "./bucket-object-util";


export const addTrashMetadata = async (url, token, bucket, storePath, oldKey, metadata) => {
  try {
    const result = await fetch(url + `/object-metadata/trash-metadata/add-trash-metadata`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        oldKey: oldKey,
        storePath: storePath,
        metadata: metadata,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const deleteFileTrashMetadata = async (url, token, bucket, storePath, oldKey, metadata) => {
  try {
    const result = await fetch(url + `/object-metadata/trash-metadata/delete-file-trash-metadata`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        oldKey: oldKey,
        storePath: storePath,
        metadata: metadata,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const deleteFolderTrashMetadata = async (url, token, bucket, storePath, oldKey, metadata) => {
  try {
    const result = await fetch(url + `/object-metadata/trash-metadata/delete-folder-trash-metadata`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        oldKey: oldKey,
        storePath: storePath,
        metadata: metadata,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getAllObjectsMetadata = async (url, token, bucket, prefix) => {
  try {
    const result = await fetch(url + `/object-metadata/all-objects-metadata?bucket=${bucket}&prefix=${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({
      //   bucket: bucket,
      //   oldKey: oldKey,
      //   storePath: storePath,
      //   metadata: metadata,
      // }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getObjectMetadata = async (url, token, bucket, key) => {
  try {
    const result = await fetch(url + `/object-metadata/object-metadata?bucket=${bucket}&key=${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({
      //   bucket: bucket,
      //   oldKey: oldKey,
      //   storePath: storePath,
      //   metadata: metadata,
      // }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const getMetadataFromMetaList = (key, allObjectsMetaList) => {
  const meta = allObjectsMetaList.find(element => {
    return element.Key === key;
  });

  if (meta && meta.objectMeta && meta.objectMeta.Metadata.metastring) {
    const parsedMetadata = JSON.parse(meta.objectMeta.Metadata.metastring);
    
    if (parsedMetadata) {
      return {
        Key: key,
        objectMeta: meta.objectMeta,
        parsedMetadata: parsedMetadata,
      };
    }
  }
};

export const getParsedMetadataFromObjectMeta = (objectMeta) => {
    const parsedMetadata = JSON.parse(objectMeta.Metadata.metastring);
    
    if (parsedMetadata) {
      return parsedMetadata
    }
};

export const isTrashboxObject = (key, allObjectsMetaList) => {

  const metadata = getMetadataFromMetaList(key, allObjectsMetaList);

  if (!metadata || !metadata.parsedMetadata) {
    return false;
  }

  const location = metadata.parsedMetadata.location;
    
  if (location === 'trashbox') {
    return true;
  }

  return false;
}

export const createParentPrefixList = (key) => {
  const splitList = key.split('/');

  const parentPrefixList = [];

  for (let i=0; i<splitList.length-2; i++) {
    const combined = splitList.slice(0, i+1).join('/');

    if (combined) {
      parentPrefixList.push(combined + '/');
    }
  }

  return parentPrefixList;
};