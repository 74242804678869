import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { useStore } from '../../../hook-store/store';

import { BACKEND_URL } from '../../../App';

import { marks } from '../../../utils/marks';
import classes from './SizesSummary.module.css';

function SizesSummary(props) {
  const {
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const {
    bucketName, 
    showObjectControl,
    bucketSizeGB,
    totalSizeGB,
    limitGB,
   } = store.bucketObjectStore;
  // const { subscriptions } = store.stripeData;
  const { 
    serviceBucketsDataList,
    serviceTotalSizeGB,
  } = store.serviceBucketsStore;
  const { gLoading, isAuth } = store.uiStore;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
 
 
  // const [showObjectControl, setShowObjectControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const sizesSummaryBody = (
    <div>
      <div className={classes.sizesSummaryTitle}>
        Kura Storage {t('sizeContent.06', 'services usage')}
      </div>
        <ul>
          {serviceBucketsDataList.map((service) => {
            let serviceName = 'Kura Storage';

            if (service.bucket.includes('image-photo')) {
              serviceName = 'Kura Image Photo';
            }

            return (
              <li key={service.bucket}
                className={classes.sizesSummaryItem}
              >
                <div>
                  <span>
                    {serviceName}
                  </span>
                  {' '}
                  <strong>
                    {numeral(service.totalSizeGB * 10**9).format('0.0b')}
                  </strong>
                </div>
              </li>
            );
          })}
        </ul>

        <div className={classes.sizesSummaryTotal}>
          <span>
            {t('sizeContent.07', 'Services total')}
          </span>
          {' '}
          <strong>
            {numeral(serviceTotalSizeGB * 10**9).format('0.0b')}
          </strong>
          {' '}
          <span>
            ({(totalSizeGB/limitGB*100).toFixed(1)}% used)
          </span>
        </div>

        
        <div>
          {t('sizeContent.08', 'It is possible to upgrade storage sizes anytime, when you need more storage space.')}
        </div>
        <div>
          <Link to="sizemanage">
            {t('sizeContent.09', 'Details of upgrade')}
          </Link>
        </div>
        

    </div>
  );



  return (
    <Fragment>
      <div>{sizesSummaryBody}</div>
    </Fragment>
  );
}

export default SizesSummary;
