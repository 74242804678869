import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../../Loader/Loader";
import ShareNotificationItem from "./ShareNotificationItem";

import { useStore } from "../../../../hook-store/store";
import { 
  updateShareNotification,
  deleteShareNotification,
} from "../../../../utils/share-bucket/share-notification-util";

import { BACKEND_URL } from "../../../../App";

import classes from './ShareNotifications.module.css';


const ShareNotifications = (props) => {
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  // const { 
  //   allBucketObjects,
  //   objectAction,
  // } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { 
    shareNotification,
    showShareNotifications,
    shareNotificationOpenTime,
    shareObjects,
  } = store.shareObjectStore;

  const [isLoading, setIsLoading] = useState(false);

  //// update notification lastOpenTime when open
  useEffect(() => {
    if (showShareNotifications) {
      updateShareNotificationsHandler();

      dispatch('SET_SHARENOTIFICATIONOPENTIME', Date.now());
    }
  },[showShareNotifications]);

  useEffect(() => {
    if (shareObjects.length > 0 && 
        shareNotification?.shareNotifications?.length > 0
    ) {
        const acceptedNotifications = checkAcceptedNotifications(
          shareObjects, 
          shareNotification.shareNotifications,
        );
        
        console.log(acceptedNotifications);
        if (acceptedNotifications?.length > 0) {
          deleteShareNotificationsHandler(acceptedNotifications);
        }
      }
  },[]);
  // useEffect(() => {
  //   if (shareNotification) {
  //     const nonOpenNotification = notOpenNotificationExist(
  //       shareNotification, 
  //       shareNotificationOpenTime,
  //     );
      
  //     dispatch('SET_NOTOPENSHARENOTIFICATIONEXIST', nonOpenNotification);
  //   }
  // },[shareNotification]);


  // const notOpenNotificationExist = (shareNotification, localLastOpenTime) => {
  //   console.log('in notOpenNotificationExist notify')
  //   const notOpenList = [];

  //   for (const notify of shareNotification.shareNotifications) {
  //     if (notify.time > shareNotification.lastOpenTime) {
  //       console.log('notify.time', notify.time, shareNotification.lastOpenTime)
  //       notOpenList.push(notify);
  //     }

  //   }

  //   if (notOpenList.length > 0) {
  //     return true;
  //   }

  //   return false;
  // };


  const updateShareNotificationsHandler = async () => {
    try { 
      setIsLoading(true);

      const resData = await updateShareNotification(
        BACKEND_URL,
        localStorage.getItem('token'),
        'lastOpenTime',
        Date.now(),
      );

      console.log(resData);

      if (resData.data && resData.data.shareNotifications &&
        resData.data.shareNotifications.length > 0) {
        dispatch('SET_SHARENOTIFICATION', resData.data);
      }
      setIsLoading(false);
      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const checkAcceptedNotifications = (shareObjects, shareNotifications) => {
    // console.log(shareObjects, shareNotifications)
    const acceptedNotificationList = [];

    for (const notify of shareNotifications) {
      const isInShareObjects = shareObjects.find(element => {
        return element.Key === notify.key && element.bucket === notify.bucket;
      });

      if (isInShareObjects) {
        acceptedNotificationList.push(notify);
      }
    }

    // console.log(acceptedNotificationList);
    return acceptedNotificationList;
  }


  const deleteShareNotificationsHandler = async (notifications) => {
    try { 
      setIsLoading(true);

      for (const notification of notifications) {
        const resData = await deleteShareNotification(
          BACKEND_URL,
          localStorage.getItem('token'),
          notification._id,
        );
  
        console.log(resData);
        
        if (resData.data && resData.data.shareNotifications) {
          dispatch('SET_SHARENOTIFICATION', resData.data);
        }
      }

      setIsLoading(false);
      // return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let shareNotificationsBody = (
    <div>{t('shareList.06', 'No share notifications')}</div>
  );

  if (shareNotification && shareNotification.shareNotifications.length > 0) {
    shareNotificationsBody = (
      <div className={classes.shareNotify}>
        <div className={classes.shareNotifyTitle}>
        {t('shareList.07', 'Share notifications')}
        </div>
        <div className={classes.shareNotifyDescription}>
        {t('shareList.08', 'There are file share invitations from other users. If you are not sure about invitations, please ignore notifications.')}
        </div>
        <ul>
          {shareNotification.shareNotifications.reverse().map(notify => {
            return (
              <li key={notify._id}>
                <ShareNotificationItem 
                  notify={notify}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <Fragment>
      {isLoading && (
        <Loader />
      )}
      {!isLoading && (
        <div>{shareNotificationsBody}</div>
      )}
    </Fragment>
  );
};

export default ShareNotifications;
