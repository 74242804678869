import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";

import AdminBucket from "../../components/Admin/AdminBucket/AdminBucket";
import { useStore } from "../../hook-store/store";

const AdminPage = (props) => {
  // const { } = props;

  const [store, dispatch] = useStore();

  return (
    <Fragment>
      <AdminBucket />
    </Fragment>
  );
};

export default AdminPage;
