import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
// import { saveAs } from 'file-saver';

import Backdrop from '../../../../Backdrop/Backdrop';
// import FileAddTrashboxMeta from '../../BucketObject/FileAction/FileAddTrashboxMeta';
// import FileDownload from '../../BucketObject/FileAction/FileDownload';
import FileShareModalContent from '../../../BucketObject/FileAction/FileShareModal/FileShareModalContent';
import ModalConfirm from '../../../../Modal/ModalConfirm';
import ToolsLink from '../../../BucketObject/FileAction/ToolsLink';

import ShareFileDownload from './ShareFileDownload';
import ShareFileStopShare from './ShareFileStopShare';

import { useStore } from '../../../../../hook-store/store';
import { 
  updateShareMetadataAcceptId,
} from '../../../../../utils/share-bucket/share-bucket-util';

import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';

// import classes from './FileActions.module.css';
import classes from './ShareFileActions.module.css';

function ShareFileActions(props) {

  const {
    setClickedActionInfoHandler,
    getObjectSignedUrlHandler,
    // fileDownloadHandler,
    deleteBucketObjectsHandler,
    selectedFileKeysHandler,
    setClickedObjectKeyHandler,
    clickedActionInfo,
    objectSignedUrl,
    isInSelectedFileKeys,
    isSharingFile,
    object,
    tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { 
  //   bucketName, 
  //   currentPrefix,
  // } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;
  const { 
    shareObjects, 
    shareOwners,
   } = store.shareObjectStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const deleteShareAcceptIdHandler = async (bucket, key, userId) => {
    try { 
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);
      
      // console.log(bucket, key, userId, shareObjects);
      // throw new Error('error-error');

      const resData = await updateShareMetadataAcceptId(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        userId,
        'delete',
      );

      console.log(resData);

      const deletedShareObjects = shareObjects.filter(object => {
        return object.Key !== key;
      });

      dispatch('SET_SHAREOBJECTS', deletedShareObjects);

      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'stop share file success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 5);

      return resData;

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'stop share file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  let shareStopModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'share-stop') {
    shareStopModalBody = (
      <ModalConfirm 
        onClose={() => { setClickedActionInfoHandler(null); }}
        onAccept={() => { 
          deleteShareAcceptIdHandler(
            object.bucket,
            object.Key,
            localStorage.getItem('userId')
          ); 
        }}
        isLoading={isLoading}
      >
        <div>
        {t('shareList.02', 'stop share')}?
        </div>
      </ModalConfirm>
    )
  }


  let fileShareModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'file-share') {
    fileShareModalBody = (
      <div>
      <Backdrop 
        zIndex={'110'}
        // backgroundColor={'rgba(0, 0, 0, 0.1)'}
        onCancel={() => { 
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      />
      <ModalConfirm
        onClose={() => { setClickedActionInfoHandler(null); }}
        hideButtons={true}
      >
        <FileShareModalContent
          object={object}
          setClickedActionInfoHandler={setClickedActionInfoHandler}
        />
      </ModalConfirm>
      </div>
    )
  }




  const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  // console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);


  const shareOwnerInfo = shareOwners.find(owner => {
    return owner.userId === object.bucket;
  });

  const ownerUserNameData = shareOwnerInfo?.userNameData;
  
  let ownerDisplayName;
  
  if (ownerUserNameData) {
    ownerDisplayName = (
      <span>
        (
          {' '}
          {ownerUserNameData.imageUrl && (
            <img className={classes.shareFileActionOwnerImage}
              src={ownerUserNameData.imageUrl}
            />
          )}
          {' '}
          {ownerUserNameData.name}
        )
        </span>
    )
  }
  
  let fileActionsBody;

  fileActionsBody = (
    <div className={classes.objectActionModal}
    // style={{display:"flex", flexDirection:"column"}}
    >
      
    <button className={classes.objectActionListItem}
      name="share-filename"
      onClick={(event) => { 
        if (!isLoading) {
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {originalName}
      {' '}
      ({numeral(object.Size).format('0.0 b')})
    </button>

    {isSharingFile && (
      <button className={classes.objectActionListItem}
        name="share-owner"
      >
        {t('shareList.03', 'your file')}
      </button>
    )}
    {!isSharingFile && (
        <button className={classes.objectActionListItem}
          name="share-owner"
        >
          {t('shareList.04', 'file owner')}: 
          {' '}
          {shareOwnerInfo && (
            <span>
              {shareOwnerInfo.email} {ownerDisplayName}
            </span>
          )}
          <br/>
          ({object.shareType})
        </button>
    )}



    <ShareFileDownload 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      object={object}
      shareOwnerInfo={shareOwnerInfo}
    />

    <ToolsLink 
      setClickedActionInfoHandler={setClickedActionInfoHandler}
      setClickedObjectKeyHandler={setClickedObjectKeyHandler}
      object={object}
    />

    {isSharingFile && (
      <button className={classes.objectActionListItem}
        disabled={isLoading}
        name="file-share"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        {marks.usersMark} {t('shareList.05', 'manage share file')}
      </button>
    )}


    {!isSharingFile && (
      <ShareFileStopShare
        setClickedActionInfoHandler={setClickedActionInfoHandler}
        object={object}
        shareOwnerInfo={shareOwnerInfo}
      />
    )}




  </div>
  )


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* {deleteModalBody} */}
      {shareStopModalBody}

      {fileShareModalBody}
      {fileActionsBody}
    </Fragment>
  );
}

export default ShareFileActions;
