import { Fragment } from "react";
import i18next from "i18next";

import Loader from "../../Loader/Loader";

import classes from "./ChangePlanModalContent.module.css";

function ChangePlanModalContent(props) {
  // console.log('cancelModalContent-props', props);
  const {
    setShowChangePlanModal,
    updateSubscription,
    selectedPriceId,
    error,
    isLoading,
  } = props;

  return (
    <Fragment>
      <div>
        <div className={classes.subscriptionModalTitle}>
          {i18next.t("subText29", "Please Confirm Your Action")}
        </div>
        <button
          className={classes.subscriptionCancelButton}
          disabled={isLoading}
          onClick={() => {
            setShowChangePlanModal(false);
          }}
        >
          {i18next.t("subText30", "Close")}
        </button>
        <button
          className={classes.subscriptionButton}
          disabled={isLoading}
          onClick={() => {
            updateSubscription(selectedPriceId);
          }}
        >
          {i18next.t("subText32", "Change Plan")}
        </button>
        
        {error && (
          <div>{error}</div>
        )}

        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ChangePlanModalContent;
