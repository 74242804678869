export const createTokenForTools = async (url, token, bucket, key) => {
  try {
    // console.log(key, bucket, socket);
    const result = await fetch(url + `/token-for-tools`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        bucket: bucket,
        key: key,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};
