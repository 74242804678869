import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import Backdrop from '../../../Backdrop/Backdrop';
import ModalPosition from '../../../Modal/ModalPosition';
import TrashActions from '../TrashActions/TrashActions';
import TrashMenuForWide from '../TrashMenu/TrashMenuForWide';

import { useStore } from '../../../../hook-store/store';
import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';
// import { 
//   // updateTrashMetadata, 
//   deleteFileTrashMetadata,
//   deleteFolderTrashMetadata,
//   getObjectMetadata,
//   getMetadataFromMetaList,
//   getParsedMetadataFromObjectMeta,
//   createParentPrefixList,
//  } from '../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from '../../BucketObject/BucketObjectList.module.css';

// import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function TrashboxList(props) {

  const {
    trashObjects,
    selectedFolder,
    selectedTrashObjects,
    selectObjectHandler,
    editSelectedTrashObjects,
    deleteTrashboxMetaHandler,
    deleteFilesFoldersHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  // const { 
  //   selectedFileKeys,
  //   movePrefixes,
  //   // topLevels,
  //   // accessList,
  //   allBucketObjects,
  //   bucketName,
  // } =store.bucketObjectStore;

  // const { 
  //   allObjectsMetaList,
  // } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;
  // const windowValues = store.uiStore.windowValues;

  const [clickedObjectKey, setClickedObjectKey] = useState('');
  const [clickedActionInfo, setClickedActionInfo] = useState();
  const [modalPositions, setModalPositions] = useState({ 
    top: null, 
    left: null,
    right: null,
    bottom: null,
    heightLimit: 300,
  });
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


 
  const createDisplayObjects = (selectedFolder, trashList) => {
    let sameLevels = [];

    if (selectedFolder) {
      sameLevels = trashList.filter(element => {
        if (!element.Key.endsWith('/')) {
          return (
            element.Key.startsWith(selectedFolder.Key) && 
            element.Key.split('/').length === selectedFolder.Key.split('/').length &&
            selectedFolder.parsedMetadata.trashMoveTime === element.parsedMetadata.trashMoveTime
          );

        } else {
          return (
            element.Key.startsWith(selectedFolder.Key) && 
            element.Key.split('/').length === selectedFolder.Key.split('/').length + 1 &&
            selectedFolder.parsedMetadata.trashMoveTime === element.parsedMetadata.trashMoveTime
          );
        }
      });

      sameLevels = _.orderBy(sameLevels, 'Key');

      const folderList = sameLevels.filter(ele => ele.Key.endsWith('/'));
      const fileList = sameLevels.filter(ele => !ele.Key.endsWith('/'));

      sameLevels = folderList.concat(fileList);

      return sameLevels;

    } else {

      let trashTopLevels = [];

      for (const element of trashList) {

        let hasSameTimeParentFolder;

        if (element.Key.endsWith('/')) {

          const splitList = element.Key.split('/');
          splitList.pop();
          splitList.pop();
          const elementParentFolderKey = splitList.join('/') + '/';

          // console.log(element.Key, elementParentFolderKey);

          hasSameTimeParentFolder = trashList.find(ele => {
            return (ele.Key === elementParentFolderKey);
          });

          // console.log(hasSameTimeParentFolder)
          if (!hasSameTimeParentFolder) {
            trashTopLevels.push(element);
          }
          
        } else {

          const elementPrefix = bucketObjectUtils.getPrefixFromKey(element.Key);

          hasSameTimeParentFolder = trashList.find(ele => {

            const elePrefix = bucketObjectUtils.getPrefixFromKey(ele.Key);
            // console.log(elePrefix === elementPrefix);

            return (
              ele.Key !== element.Key && ele.Key.endsWith('/') &&
              elementPrefix && elePrefix === elementPrefix &&
              element.parsedMetadata.trashMoveTime === ele.parsedMetadata.trashMoveTime
            );
          });

          // console.log(hasSameTimeParentFolder)
          if (!hasSameTimeParentFolder) {
            trashTopLevels.push(element);
          }
        }
      }

      trashTopLevels = _.uniqBy(trashTopLevels, 'Key');
      trashTopLevels = _.orderBy(trashTopLevels, 'Key');
      
      const folderList = trashTopLevels.filter(ele => ele.Key.endsWith('/'));
      const fileList = trashTopLevels.filter(ele => !ele.Key.endsWith('/'));
      
      trashTopLevels = folderList.concat(fileList);
      // console.log(trashTopLevels);

      return trashTopLevels;
    }
  };


  const setClickedObjectKeyHandler = (key) => {
    if (key === clickedObjectKey) {
      setClickedObjectKey('');
    } else {
      setClickedObjectKey(key);
    }
    console.log(key);
  };

  const showActionModalHandler = (key, type) => {
    console.log(key, type);
    let targetEl;
    let targetRect;

    if (type === 'file') {
      targetEl = document.getElementById(`file-action-${key}`);
      targetRect = targetEl.getBoundingClientRect();
    }

    if (type === 'folder') {
      targetEl = document.getElementById(`folder-action-${key}`);
      targetRect = targetEl.getBoundingClientRect();
      
      }

      // const bottomSpace = window.innerHeight - targetRect.top;
      // console.log(bottomSpace);
      const heightLimit = 300;

      console.log(targetRect.top, window.innerHeight - targetRect.top, window.innerHeight)
      
      if (window.innerHeight - targetRect.top > heightLimit) {
        setModalPositions({
          top: targetRect.top ,
          left: targetRect.left - 200,
          right: null,
          // right: targetRect.left,
          bottom: null,
          heightLimit: heightLimit,
        });
      } else {
        setModalPositions({
          top: null,
          left: targetRect.left - 200,
          right: null,
          bottom: window.innerHeight - targetRect.bottom,
          heightLimit: targetRect.bottom,
        });
      }

      console.log(targetEl);
      console.log(targetRect);
    };

    const setClickedActionInfoHandler = (event, key) => {
      if (key) {
        setClickedActionInfo({
          name: event.target.name,
          key: key,
          date: Date.now(),
        });
      } else {
        setClickedActionInfo(null);
      }
    };

    const actionClickHandler = (object) => {
      if (object.Key.endsWith('/')) {
        setClickedObjectKeyHandler(object.Key); 
        showActionModalHandler(object.Key, 'folder'); 
        setClickedActionInfoHandler(null);

      } else {
        setClickedObjectKeyHandler(object.Key); 
        showActionModalHandler(object.Key, 'file'); 
        setClickedActionInfoHandler(null);
      }
    }

  console.log(createDisplayObjects(selectedFolder, trashObjects));



  let topLevelsBody;

  let displayList = trashObjects;

  // displayList = createDisplayObjects(selectedFolder, trashObjects);

  if (selectedFolder) {
    // console.log(createDisplayObjects(selectedFolder, trashObjects));
    displayList = createDisplayObjects(selectedFolder, trashObjects);
  }

  // console.log(createDisplayObjects(selectedFolder, trashObjects));
  displayList = createDisplayObjects(selectedFolder, trashObjects);


  if (displayList.length === 0) {
    topLevelsBody = (
      <tr>
        <td></td>
        <td>no content</td>
      </tr>
    );
  }

  if (displayList.length > 0) {
    topLevelsBody = (
      <tbody>
        {displayList.map((object, index) => {
          // console.log('accessList object', object)
          
          let objectActionElementId = `file-action-${object.Key}`;

          if (object.Key.endsWith('/')) {
            objectActionElementId = `folder-action-${object.Key}`;
          }

          // const fileActionElementId = `file-action-${object.Key}`;
          // const folderActionElementId= `folder-action-${object.Key}`;

        const isInSelectedTrashObjects = selectedTrashObjects.find(obj => {
          return obj.Key === object.Key;
        });

        let objectName;
        let objectMark;
        
        if (object.Key.endsWith('/')) {
          // const folderName = object.Key.split('/')[object.Key.split('/').length -2];
          // const timeEndIndex = folderName.indexOf('Z');
          // const originalName = folderName.substring(timeEndIndex+2);
          // objectName = originalName;
          const trashFolderName = bucketObjectUtils.getFolderNameFromKey(object.Key);
          
          // console.log(trashFolderName);
          if (trashFolderName.startsWith('trash-')) {
            const trashYIndex = trashFolderName.indexOf('Y');
            objectName = trashFolderName.substring(trashYIndex + 2);
          } else {
            objectName = trashFolderName;
          }
          // console.log(objectName)
          objectMark = marks.folderFillMark;
        }
        else {
          objectName = bucketObjectUtils.getOriginalFileNameFromKey(object.Key);
          objectMark = createFileMark(object);
        }


        return (
          <tr key={index} >
            <td>
              <span                         
                onClick={() => { 
                  if (!isLoading) {
                    editSelectedTrashObjects(object);
                    // folderClickSelectHandler(isInMovePrefixes, object.Key);
                  }
                }}
              >
                {isInSelectedTrashObjects && (
                  <span>
                    {marks.checkMark}
                  </span> 
                )}
                {!isInSelectedTrashObjects && (
                  <span>
                    {marks.noCheckMark}
                  </span>            
                )}
              </span>
            </td>
            <td>
              {/* {folderNameBody} */}
              <span
                onClick={() => {    
                  if (!isLoading) {
                    selectObjectHandler(object.Key, trashObjects);
                  }            
                }}
              >
                {objectMark} {objectName}
              </span>
            </td>
            <td>
              <span 
                id={objectActionElementId}
                title={'select action for object'}
                onClick={() =>{ 
                  if (!isLoading) {
                    actionClickHandler(object);
                  }
                }}
              >
                {t('trash.04', 'trash action')} {marks.triangleDown}
              </span>
              {clickedObjectKey === object.Key && (
                <div>
                  <Backdrop 
                    zIndex={'90'}
                    // backgroundColor={'rgba(0, 0, 0, 0.1)'}
                    backgroundColor={'rgba(0, 0, 0, 0)'}
                    onCancel={() => { 
                      if (!isLoading) {
                        setClickedObjectKeyHandler(''); 
                        setClickedActionInfo(null);
                      }
                    }}
                  />
                  <ModalPosition 
                    top={modalPositions.top}
                    left={modalPositions.left}
                    right={modalPositions.right}
                    bottom={modalPositions.bottom}
                    heightLimit={modalPositions.heightLimit}
                    zIndex='100'
                    // onClose={() => {
                    //   setClickedObjectKeyHandler(''); 
                    //   setClickedActionInfo(null);
                    // }}
                  >
                    <TrashActions
                      // deleteFolderHandler={deleteFolderHandler}
                      // folderClickSelectHandler={folderClickSelectHandler}
                      // setMovePrefixesHandler={setMovePrefixesHandler}
                      setClickedActionInfoHandler={setClickedActionInfoHandler}
                      setClickedObjectKeyHandler={setClickedObjectKeyHandler}
                      clickedActionInfo={clickedActionInfo}
                      object={object}
                      trashObjects={trashObjects}
                      deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
                      deleteFilesFoldersHandler={deleteFilesFoldersHandler}
                      selectedTrashObjects={selectedTrashObjects}
                      // isInMovePrefixes={isInMovePrefixes}
                      isLoading={isLoading}
                    />
                  </ModalPosition>
                </div>
              )}
            </td>
            {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
              <td>
                {object.parsedMetadata && (
                  moment(object.parsedMetadata.trashMoveTime).format("YYYY-MM-DD h:mm a")
                  // moment(object.parsedMetadata.trashMoveTime).format("YYYY-MM-DD")
                )}
              </td>
            )}
          </tr>
        );
      })}
      </tbody>
    );
  }




  return (
    <Fragment>
    <div className={classes.objectTableView}>
      <div className={classes.objectTableContainer}>
        
        <div className={classes.objectTableMenu}>
            <TrashMenuForWide />
        </div>


        <table className={classes.objectTable}>
          <thead>
            <tr>
              <th>
                {/* {isAllSelected && (
                <span
                  onClick={() => {
                    if (!isLoading) {
                      deSelectAllObjectsHandler();
                    }
                  }}
                >
                  {marks.checkMark}
                </span>
                )}
                {!isAllSelected && (
                <span
                  onClick={() => {
                    if (!isLoading) {
                      selectAllObjectsHandler(trashObjects);
                    }
                  }}
                >
                  {marks.noCheckMark}
                  </span>
                )} */}
              </th>
              <th>{t('trash.02', 'name')}</th>
              <th></th>
              {(window.innerHeight >= 480 && window.innerWidth >= 480) && (
                <th>{t('trash.03', 'time')}</th>
              )}
            </tr>
          </thead>
          {topLevelsBody}
        </table>
      </div>
      </div>
    </Fragment>
  );
}

export default TrashboxList;
