import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Backdrop from '../../Backdrop/Backdrop';
import Folder from '../Folder/Folder';
import Modal from '../../Modal/Modal';
// import SelectedObjects from './SelectedObjects';
import SelectedObjectsDelete from './SelectedObjectsDelete';
import SelectedObjectsAddTrashboxMeta from './SelectedObjectsAddTrashboxMeta';

import { useStore } from '../../../hook-store/store';

import { marks } from '../../../utils/marks';

import classes from './SelectedObjectsControl.module.css';

function SelectedObjectsControll(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;
  const objectsActionType = store.bucketObjectStore.objectsActionType;
  const showFoldersModal = store.bucketObjectStore.showFoldersModal;

  const gLoading = store.uiStore.gLoading;

  // const [showFoldersModal, setShowFoldersModal] = useState(false);
  const [showSelectedObjectsActions, setShowSelectedObjectsActions] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (movePrefixes.length > 0 || selectedFileKeys.length > 0) {
      setShowSelectedObjectsActions(true);
    } else {
      setShowSelectedObjectsActions(false);
    }
  },[movePrefixes, selectedFileKeys])

  const showFoldersModalHandler = (value) => {
    if (!value) {
      dispatch('SET_SELECTED_PREFIX', '');
    }
    // setShowFoldersModal(value);
    dispatch('SET_SHOWFOLDERSMODAL', value);
  }



  
  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {showFoldersModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                showFoldersModalHandler(false); 
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                showFoldersModalHandler(false); 
              }
            }}
          >
            <Folder />
          </Modal>
        </div>
      )}

      {showSelectedObjectsActions && (
        <section className={classes.objectsActionsContainer}>
          <div className={classes.objectsActionsTitle}>
            {t('selectedObject.01', 'actions')}
          </div>
          <div className={classes.objectsActionsTitleSeparator}>
          </div>

          <div className={classes.objectsActionItemContainer}>
            {/* <div className={classes.objectsActionsItem}
              onClick={() => {
                if (!isLoading) {
                  dispatch('SET_OBJECTS_ACTIONTYPE', 'delete');
                }
              }}
            >
              {marks.trashMark} {t('selectedObject.02', 'delete')}
            </div> */}

            {/* {objectsActionType === 'delete' && (
              <SelectedObjectsDelete />
            )} */}

            <div className={classes.objectsActionsItem}
              onClick={() => { 
                if (!isLoading) {
                  showFoldersModalHandler(true); 
                  dispatch('SET_OBJECTS_ACTIONTYPE', 'copy');
                }
              }}
            >
              {marks.copyMark} {t('selectedObject.03', 'copy')}
            </div>
            <div className={classes.objectsActionsItem}
              onClick={() => { 
                if (!isLoading) {
                  showFoldersModalHandler(true); 
                  dispatch('SET_OBJECTS_ACTIONTYPE', 'move-delete');
                }
              }}
            >
              {marks.moveMark} {t('selectedObject.04', 'move')}
            </div>
            <div className={classes.objectsActionsItem}
              onClick={() => {
                if (!isLoading) {
                  dispatch('SET_OBJECTS_ACTIONTYPE', 'add-trash-meta');
                }
              }}
            >
              {marks.trashMark} {t('trash.13', 'move to trash box')}
            </div>

          </div>

          {objectsActionType === 'delete' && (
            <SelectedObjectsDelete />
          )}

          {objectsActionType === 'add-trash-meta' && (
            <SelectedObjectsAddTrashboxMeta />
          )}
        
        </section>
      )}

    </Fragment>
  );
}

export default SelectedObjectsControll;
