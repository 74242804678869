import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import numeral from 'numeral';

import { useStore } from '../../../../../hook-store/store';
// import { 
//   getMetadataFromMetaList,
//  } from '../../../../../utils/bucket/object-metadata-util';
import { 
  getSharingUsers,
} from '../../../../../utils/share-bucket/share-bucket-util';

import { marks } from '../../../../../utils/marks';

import classes from './FileSharingUsers.module.css';


function FileSharingUsers(props) {

  const {
    setClickedActionInfoHandler,
    object,
    shareInfoMeta,
    // isLoading,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { 
  //   bucketName, 
  //   currentPrefix,
  // } = store.bucketObjectStore;
  const { allObjectsMetaList } = store.objectMetadataStore;
  const { sharingUsers } = store.shareObjectStore;
  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  // let shareInfoMeta;
  let sharingUserInfoList = [];
  let notAcceputUserList = [];
  // const objectMeta = getMetadataFromMetaList(object.Key, allObjectsMetaList);
  // console.log(objectMeta)

  if (shareInfoMeta) {
    const {
      sharingReadUsers, 
      sharingWriteUsers,
      notAcceptReadUsers,
      notAcceptWriteUsers,
     } = getSharingUsers(shareInfoMeta, sharingUsers);
    
    console.log('sharingUserlists', getSharingUsers(shareInfoMeta, sharingUsers))
    const onlyReadUsers = [];

    for (const readUser of sharingReadUsers) {
      const isInWrite = sharingWriteUsers.find(user => {
        return user.userId === readUser.userId;
      });

      if (!isInWrite) {
        onlyReadUsers.push(readUser);
      }
    }

    sharingUserInfoList = onlyReadUsers.concat(sharingWriteUsers);
    // console.log('shareUsers', sharingReadUsers, sharingWriteUsers);

    notAcceputUserList = notAcceptReadUsers.concat(notAcceptWriteUsers);
  }
  

  // console.log('sharingUserInfoList', sharingUserInfoList);
  let notAcceptUsersBody;

  if (notAcceputUserList.length > 0) {
    notAcceptUsersBody = (
      <div className={classes.fileSharingUsersSection}>
        <div>
          {t('fileAction.09', 'Not yet accept users')}
        </div>
        <ul className={classes.fileSharingUsersList}>
          {notAcceputUserList.map((user, index) => {
            
            const sUserNameData = user.userNameData ? user.userNameData : null;
            let displayName;

            if (sUserNameData) {
              displayName = (
                <span>
                  (
                    {' '}
                    {sUserNameData.imageUrl && (
                      <img className={classes.fileSharingUsersUserImage}
                        src={sUserNameData.imageUrl}
                      />
                    )}
                    {' '}
                    {sUserNameData.name}
                  )
                </span>
              );
            }

            return (
              <li key={index}>
                {user.email} {displayName}
                {' '}
                <strong>{user.type}</strong>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
 


  let fileSharingUsersBody;

  if (sharingUserInfoList.length === 0) {
    fileSharingUsersBody = (
      <div className={classes.fileSharingUsersSection}>
        {t('fileAction.10', 'No sharing users')}
      </div>
    )
  } else {
    fileSharingUsersBody = (
      <div className={classes.fileSharingUsersSection}>
        <div>{t('fileAction.11', 'File sharing users')}</div>
        {/* {shareInfoMeta && (
          <div>
            {JSON.stringify(shareInfoMeta)}
          </div>
        )} */}

        <ul className={classes.fileSharingUsersList}>
          {sharingUserInfoList.map((user, index) => {
            const sUserNameData = user.userNameData ? user.userNameData : null;
            let displayName;

            if (sUserNameData) {
              displayName = (
                <span>
                  (
                    {' '}
                    {sUserNameData.imageUrl && (
                      <img className={classes.fileSharingUsersUserImage}
                        src={sUserNameData.imageUrl}
                      />
                    )}
                    {' '}
                    {sUserNameData.name}
                  )
                </span>
              );
            }

            return (
              <li key={index}>
                {user.email} {displayName}
                {' '}
                <strong>{user.type}</strong>
              </li>
            );
          })}
        </ul>
        
      </div>
    );
  }



  return (
    <Fragment>
        {/* {shareInfoMeta && (
          <div>
            {JSON.stringify(shareInfoMeta)}
          </div>
        )} */}

      <div>
        {fileSharingUsersBody}
      </div>
      
      <div>
        {notAcceptUsersBody}
      </div>
    </Fragment>
  );
}

export default FileSharingUsers;
