import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

import { marks } from '../../../../utils/marks';
import classes from './FolderActions.module.css';

function FolderAddTrasboxMeta(props) {

  const {
    setClickedActionInfoHandler,
    object,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  // const { 
  //   bucketName,
  //   allBucketObjects,
  //   currentPrefix,
  //   topLevels,
  //  } = store.bucketObjectStore;

  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);




  let folderAddTrasboxMetaBody;

  if (object) {
    folderAddTrasboxMetaBody = (
      <button className={classes.objectActionListItem}
        disabled={isLoading}
        name="folder-add-trashbox-meta"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            // setClickedObjectKeyHandler('');
          }
        }}
      >
        {marks.trashMark} {t('trash.13', 'move to trash box')}
      </button>
    )
  }

 



  return (
    <Fragment>
      {folderAddTrasboxMetaBody}
    </Fragment>
  );
}

export default FolderAddTrasboxMeta;
