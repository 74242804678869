import _ from 'lodash';

export const changeSelectedFileKeys = (inputKey, action, selectedFileKeys) => {
  // const actionList = selectedFileKeys;

  if (action === 'add') {
    const isInList = selectedFileKeys.find(key => {
      return key === inputKey;
    });

    if (!isInList) {
      const addedList = selectedFileKeys.concat(inputKey);
      
      // dispatch('SET_SELECTED_FILEKEYS', addedList);
      return addedList;
    }
  }

  if (action === 'delete') {
    const deletedList = selectedFileKeys.filter(key => {
      return key !== inputKey;
    });

    // dispatch('SET_SELECTED_FILEKEYS', deletedList);
    return deletedList;
  }

  if (action === 'reset') {
    // dispatch('SET_SELECTED_FILEKEYS', []);
    return [];
  }
};


export const createDeletedObjectList = (keys, topLevels) => {

  const deletedKeys = [];
    
  for (const key of keys) {
    deletedKeys.push({
      Key: key,
    });
  }

  const sameElements = _.intersectionBy(topLevels, deletedKeys, 'Key');
  const deletedList = _.difference(topLevels, sameElements);

  return deletedList;
};

export const createFoldersDeletedObjectList = (keys, topLevels, currentPrefix) => {

  let deletedList;

  if (!currentPrefix) {
    const deletedKeys = [];
      
    for (const key of keys) {
      deletedKeys.push({
        Prefix: key,
        Key: key
      });
    }
  
    const sameElements = _.intersectionBy(topLevels, deletedKeys, 'Prefix');
    deletedList = _.difference(topLevels, sameElements);
    
    // console.log(deletedKeys, sameElements, deletedList);
  } 
  else {
    const deletedPrefixes = [];
      
    for (const key of keys) {
      deletedPrefixes.push({
        Key: key,
      });
    }

    const sameElements = _.intersectionBy(topLevels, deletedPrefixes, 'Key');
    deletedList = _.difference(topLevels, sameElements);
  }

  return deletedList;
};