import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

// import Loader from '../../Loader/Loader';
import { useStore } from "../../../hook-store/store";
import { BACKEND_URL } from "../../../App";

// import * as adminBucketObjectUtils from '../../../utils/admin-bucket/admin-bucket-object-util';

// import classes from './AdminBucketObjectList.module.css';

function AdminObjectListItem(props) {
  const { 
    object, 
    selectedKey, 
    selectedKeys, 
    setSelectedKeysHandler, 
  } = props;

  const [store, dispatch] = useStore();
  const gLoading = store.uiStore.gLoading;
  // const adminSelectedBucket = store.adminStore.adminSelectedBucket;
  // const adminAllBucketObjects = store.adminStore.adminAllBucketObjects;

  let nameColor = "";
  if (object.Key.endsWith("/")) {
    nameColor = "gray";
  }

  let txDeco = 'none';
  const isInSelectedKeys = selectedKeys.find(key => key === object.Key);

  if (isInSelectedKeys) {
    txDeco = 'underline';
  }
  let objectItemBody = (
    <div>
      <span
        style={{ color: nameColor, textDecoration: txDeco }}
        onClick={() => {
          // showActionsHandler(object.Key);
          setSelectedKeysHandler(object.Key);
        }}
      >
        {object.Key}
      </span>
    </div>
  );

  return <Fragment>{objectItemBody}</Fragment>;
}

export default AdminObjectListItem;
