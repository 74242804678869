////  https://fontawesome.com/v4/icons/
// import classes from './marks.module.css';

export const marks = {
  checkMark: <strong style={{color: 'gray'}} >&#9745;</strong>, //// ☑
  noCheckMark: <span style={{color: 'gray'}} >&#9744;</span>, //// ☐
	
  fileMark: <i style={{color: 'gray'}} className="fa fa-file-o"></i>,
  fileFillMark: <i style={{color: 'gray'}} className="fa fa-file" aria-hidden="true"></i>,
  folderMark: <i style={{color: 'gray'}} className="fa fa-folder-o"></i>,
  folderOpenMark: <i style={{color: 'gray'}} className="fa fa-folder-open-o" aria-hidden="true"></i>,
  folderFillMark: <i style={{color: 'gray'}} className="fa fa-folder"></i>,
  folderFillOpenMark: <i style={{color: 'gray'}} className="fa fa-folder-open" aria-hidden="true"></i>,
  fileAudioOMark: <i style={{color: 'gray'}} className="fa fa-file-audio-o" aria-hidden="true"></i>,
  filePdfOMark: <i style={{color: 'gray'}} className="fa fa-file-pdf-o" aria-hidden="true"></i>,
  fileImageOMark: <i style={{color: 'gray'}} className="fa fa-file-image-o" aria-hidden="true"></i>,
  fileVideoOMark: <i style={{color: 'gray'}} className="fa fa-file-video-o" aria-hidden="true"></i>,
  fileTextOMark: <i style={{color: 'gray'}} className="fa fa-file-text-o" aria-hidden="true"></i>,
  fileCodeOMark: <i style={{color: 'gray'}} className="fa fa-file-code-o" aria-hidden="true"></i>,

  trashMark: <i style={{color: 'gray'}} className="fa fa-trash" aria-hidden="true"></i>,
  editMark: <i style={{color: 'gray'}} className="fa fa-pencil-square-o" aria-hidden="true"></i>,

  triangle: <span style={{color: 'gray'}} >&#9652;</span>,
  triangleDown: <span style={{color: 'gray'}} >&#9662;</span>,
  arrowCircleUp: <i style={{color: 'gray'}} className="fa fa-arrow-circle-up"></i>,
  
  spinner: <i style={{color: 'gray'}}  className="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>,
  
  // closeMark: <i className="fa fa-times" aria-hidden="true"></i>,
  closeMark: <span style={{color: 'gray'}}>&#x2715;</span>,

  uploadMark: <i style={{color: 'gray'}} className="fa fa-upload" aria-hidden="true"></i>,
  downloadMark: <i style={{color: 'gray'}}  className="fa fa-download" aria-hidden="true"></i>,

  signInMrak: <i style={{color: 'gray'}} className="fa fa-sign-in" aria-hidden="true"></i>,
  signOutMrak: <i style={{color: 'gray'}} className="fa fa-sign-out" aria-hidden="true"></i>,
  userPlusMrak: <i style={{color: 'gray'}} className="fa fa-user-plus" aria-hidden="true"></i>,
  userMrak: <i style={{color: 'gray'}} className="fa fa-user" aria-hidden="true"></i>,
  usersMark: <i style={{color: 'gray'}} className="fa fa-users" aria-hidden="true"></i>,

  gearMrak: <i style={{color: 'gray'}} className="fa fa-cog" aria-hidden="true"></i>,
  bellMrak: <i style={{color: 'gray'}} className="fa fa-bell" aria-hidden="true"></i>,

  copyMark: <i style={{color: 'gray'}} className="fa fa-files-o" aria-hidden="true"></i>,
  moveMark: <i style={{color: 'gray'}} className="fa fa-share" aria-hidden="true"></i>,
  replyMark: <i style={{color: 'gray'}} className="fa fa-reply" aria-hidden="true"></i>,

  listUlMark: <i style={{color: 'gray'}} className="fa fa-list-ul" aria-hidden="true"></i>,
  tableMark: <i style={{color: 'gray'}} className="fa fa-table" aria-hidden="true"></i>,

  plusSquareOMark: <i style={{color: 'gray'}} className="fa fa-plus-square-o" aria-hidden="true"></i>,
  plusSquareMark: <i style={{color: 'gray'}} className="fa fa-plus-square" aria-hidden="true"></i>,
  dotCircleOMark: <i style={{color: 'gray'}} className="fa fa-dot-circle-o" aria-hidden="true"></i>,

  externalLinkMark: <i style={{color: 'gray'}} className="fa fa-external-link" aria-hidden="true"></i>,

  ellipsisVMark: <i style={{color: 'gray'}} className="fa fa-ellipsis-v" aria-hidden="true"></i>,
  ellipsisHMark: <i style={{color: 'gray'}} className="fa fa-ellipsis-h" aria-hidden="true"></i>,
  videoCameraMark: <i style={{color: 'gray'}} className="fa fa-video-camera" aria-hidden="true"></i>,

  volumeUpMark: <i style={{color: 'gray'}} className="fa fa-volume-up" aria-hidden="true"></i>,
};