import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    // SET_TOOLSPAGEOPEN: (state, payload) => {
    //   return { 
    //     // ...state,
    //     forToolsStore : {
    //       ...state.forToolsStore,
    //       toolsPageOpen: payload, 
    //     }
    //   };
    // },

  };

  initStore(actions, {
    forToolsStore: {
      // toolsPageOpen: false,
    }
  });
};

export default configureStore;
