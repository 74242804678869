// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  const title = `Kura Storage - ${t('headMeta01', 'Simple file storage, file share, and collaboration tools. Store, access, and download files from your devices everywhere')}`;

  const description = `Kura Storage by SpaceEight 
${t('headMeta03', 'is simple storage place for your files with file sharing and collaboration tools.')}

${t('headMeta04', 'User can upload, store, access, and download files. Access files from desktop and mobile devices.')} 

${t('headMeta05', 'It is possible to edit uploaded Word file with simple text editor, edit excel file with spreadsheet, create slide presentation file.')}

${t('headMeta06', 'File sharing with other users allows to collaborate on various files such as documents, spreadsheets, and slide presentation files.')}
${t('headMeta07', 'Synchronized file editing enables smooth collaboration between remote users.')}
`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;