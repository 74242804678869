
  export const getBuckets = async (url, token) => {
    try {
      const result = await fetch(url + '/bucket', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      const resData = await result.json();
      console.log(result, resData);
  
      if (result.ok) {
        return resData;
      } else {
        throw new Error('something wrong');
      }

    } catch(err) {
      console.log(err);
      throw new Error('');
    }
  };

  export const createBucket = async (url, token, bucketname) => {
    try {
      console.log(bucketname);
      const result = await fetch(url + '/bucket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        // ....
        body: JSON.stringify({
          bucket: bucketname
        }),
      });
  
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }
    
    } catch(err) {
      console.log(err);
      throw new Error('');
    }
  };


  export const getUserBucket = async (url, token, userId) => {
    try {
      // const result = await fetch(url + `/bucket/user-bucket?userId=${userId}`, {
      const result = await fetch(url + `/bucket/user-bucket`, {  
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      const resData = await result.json();
      console.log(result, resData);
  
      if (result.ok) {
        return resData;
      } 
      if (result.status === 404) {
        return 'not-found';
      }
      else {
        throw new Error('something wrong');
      }

    } catch(err) {
      console.log(err);
      throw new Error('');
    }
  };