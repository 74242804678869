import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

// import './App.css';

import AccountSubscription from './AccountSubscription';
import AccountSubscription2 from './AccountSubscription2';
// import Language from './components/language/Language';


const AccountSubscriptionList = (props) => {

  const { subscriptions } = props;

  let subscriptionListBody;
  subscriptionListBody = (
    <div>
      <h2>
        {/* Subscriptions */}
        {i18next.t('subText3', 'Subscription')}
      </h2>

      <div id="subscriptions">
        {subscriptions.length > 0 
          ? subscriptions.map((s) => {
              // console.log(s);
              if (
                s.status === 'active' 
                || s.status === 'past_due' 
                || s.status === 'unpaid' 
                || s.status === 'trialing'
              ) {
                return <span key={s.id}>
                    <AccountSubscription2  subscription={s} />
                    {/* <AccountSubscription  subscription={s} /> */}
                  </span>
              }
              // if (s.status === 'incomplete') {
              //   return;
              // } else {
              //   return <AccountSubscription key={s.id} subscription={s} />
              // }
            })
          : ''
      }
      </div>
    </div>
  );

  return (
   <Fragment>{subscriptionListBody}</Fragment>
  );
}

export default AccountSubscriptionList;
