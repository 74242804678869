export const storeObjectAccess = async (url, token, key) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/object-access`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        key: key,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw new Error('');
  }
};


export const getObjectAccess = async (url, token) => {
  try {
    // console.log(key, bucket);
    const result = await fetch(url + `/object-access`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({
      //   key: key,
      // }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};