import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_PRICES: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          prices: payload,
        }
      }
    },
    SET_SUBSCRIPTIONS: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          subscriptions: payload,
        }
      }
    },
    SET_SUBSCRIPTIONCONFIG: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          subscriptionConfig: payload,
        }
      }
    },
    SET_DEV02SIZEGB: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          dev02SizeGB: payload,
        }
      }
    },
    SET_MINISIZEGB: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          miniSizeGB: payload,
        }
      }
    },
    SET_BASICSIZEGB: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          basicSizeGB: payload,
        }
      }
    },
    SET_ISSTRIPEAPI: (state, payload) => {
      return {
        // ...state,
        stripeData : {
          ...state.stripeData,
          isStripeApi: payload,
        }
      }
    },


  };

  initStore(actions, {
    stripeData: {
      // customer: null,
      // haveSubscription: false,
      subscriptions: [],
      // activeSubscriptions: [],
      prices: [],

      subscriptionConfig: [],
      dev02SizeGB: 0.2,
      miniSizeGB: 100,
      basicSizeGB: 500,

      isStripeApi: false,
      // currentSubscription: undefined,
      // subscriptionInfo: {
      //   subscriptionId: null,
      //   status: null,
      //   priceId: null,
      //   //.....
      // }
    }
  });
};

export default configureStore;
