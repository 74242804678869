import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';


import { useStore } from '../../../../hook-store/store';
import { createTokenForTools } from '../../../../utils/bucket/object-for-tools';
import { 
  getMetadataFromMetaList,
  getParsedMetadataFromObjectMeta,
 } from '../../../../utils/bucket/object-metadata-util';
import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL, kuraToolsLink } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';


function ToolsLink(props) {

  const {
    setClickedActionInfoHandler,
    setClickedObjectKeyHandler,
    object,
    // tokenForToolsHandler,
    // isLoading,
  } = props;

  // console.log('ToolsLink.js-props', props, object.Key);
    const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const { bucketName } = store.bucketObjectStore;
  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  const [showForImageModal, setShowForImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('object', object);

  let isPresentationFile = false;

  //// user's file case
  const objectMetadata = getMetadataFromMetaList(object.Key, allObjectsMetaList);

  if (objectMetadata && objectMetadata.parsedMetadata.isPresentationFile) {
    isPresentationFile = true;
  }

  //// share file case
  if (object.objectMeta) {
    const parsedMeta = getParsedMetadataFromObjectMeta(object.objectMeta);

    if (parsedMeta && parsedMeta.isPresentationFile && 
        parsedMeta.shareInfo && parsedMeta.shareInfo.ownerId !== localStorage.getItem('userId')
    ) {
      isPresentationFile = true;
    }
    // console.log('object parsedMeta', parsedMeta);
  }

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);




  const tokenForToolsHandler = async (url, token, bucket, key) => {
    try {

      const tokenForTools = await createTokenForTools(
        url,
        token,
        bucket,
        key,
      );

      // console.log(tokenForTools);

      const tokenForCasTGT = tokenForTools.tokenForCasTGT;
      const TGTexp = tokenForTools.TGTexp;
      

      if (tokenForCasTGT && TGTexp) {
        window.open(`${kuraToolsLink}/fileload?tokenForCasTGT=${tokenForCasTGT}&TGTExp=${TGTexp}`, '_blank');
        // window.open(`${kuraToolsLink}/fileload?tokenForCasTGT=${tokenForCasTGT}&TGTExp=${TGTexp}`,"_self");
      }

    } catch(err) {
      console.log(err);
    }
  }
  
  let fileBucket = bucketName;

  if (object.bucket && object.bucket !== bucketName) {
    fileBucket = object.bucket;
  }

  let toolsLinkBody
 
  // if (object && object.mimeType && object.mimeType.split('/')[0] === 'text') {
  //   toolsLinkBody = (
  //     <button className={classes.objectActionListItem}
  //       disabled={isLoading}
  //       name="edit-file"
  //       onClick={(event) => {
  //         if (!isLoading) {
  //           setClickedActionInfoHandler(event, object.Key);
  //           setClickedObjectKeyHandler('');
  //           tokenForToolsHandler(
  //             BACKEND_URL,
  //             localStorage.getItem('token'),
  //             bucketName,
  //             object.Key,
  //           );
  //         }
  //       }}
  //     >
  //       {marks.editMark} edit-file-open {marks.externalLinkMark}
  //     </button>
  //   );
  // }

  // if (object && object.mimeType && object.mimeType.split('/')[0] === 'image') {
  //   toolsLinkBody = (
  //     <button className={classes.objectActionListItem}
  //     onClick={() => {
  //       if (!isLoading) {
  //         setShowForImageModal(true);
    
  //       }
  //     }}
  //     >
  //       info-about-image-photo-editor-and-page {showForImageModal && 'show'}
  //     </button>
  //   );
  // }

  if (object && object.mimeType === mimeTypes.docx) {
    toolsLinkBody = (
      <button className={classes.objectActionListItem}
        disabled={isLoading}
        name="edit-word-file"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            setClickedObjectKeyHandler('');
            tokenForToolsHandler(
              BACKEND_URL,
              localStorage.getItem('token'),
              fileBucket,
              object.Key,
            );
          }
        }}
      >
        {marks.editMark} {t('fileAction.04', 'open in text editor')} {marks.externalLinkMark}
      </button>
    );
  }

  if (object && object.mimeType === mimeTypes.xlsx) {
    toolsLinkBody = (
      <button className={classes.objectActionListItem}
        disabled={isLoading}
        name="edit-excel-file"
        onClick={(event) => {
          if (!isLoading) {
            setClickedActionInfoHandler(event, object.Key);
            setClickedObjectKeyHandler('');
            tokenForToolsHandler(
              BACKEND_URL,
              localStorage.getItem('token'),
              fileBucket,
              object.Key,
            );
          }
        }}
      >
        {marks.editMark} {t('fileAction.07', 'open in spreadsheet')} {marks.externalLinkMark}
      </button>
    );
  }

  if (object && object.mimeType === mimeTypes.txt && isPresentationFile
      // objectMetadata && objectMetadata.parsedMetadata.isPresentationFile
    ) {
      toolsLinkBody = (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="edit-presentation-file"
          onClick={(event) => {
            if (!isLoading) {
              setClickedActionInfoHandler(event, object.Key);
              setClickedObjectKeyHandler('');
              tokenForToolsHandler(
                BACKEND_URL,
                localStorage.getItem('token'),
                fileBucket,
                object.Key,
              );
            }
          }}
        >
          {marks.editMark}{' '}
          {t('fileAction.08', 'open in slide editor')}{' '}
          {marks.externalLinkMark}
        </button>
      );
  }


  return (
    <Fragment>
      {toolsLinkBody}
      {/* {showForImageModal && (
        <div>
          <Backdrop 
            onCancel={() => {
              if (!isLoading) {
                setShowForImageModal(false);
              }
            }}
          />
          <ModalConfirm
            hideButtons={true}
            onClose={() => {
              if (!isLoading) {
                setShowForImageModal(false);
              }
            }}
          >
            about-image-photo-editor
          </ModalConfirm>
        </div>
      )} */}
    </Fragment>
  );
}

export default ToolsLink;
