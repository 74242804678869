import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import { useStore } from '../../../../../hook-store/store';

// import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
// import * as objectDownloadUtils from '../../../../utils/bucket/object-download-util';
// import { getShareObjectSignedUrl } from '../../../../utils/share-bucket/share-bucket-object-util';

import { mimeTypes } from '../../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';
import classes from './ShareFileActions.module.css';



function ShareFileStopShare(props) {

  const {
    setClickedActionInfoHandler,
    object,
    shareOwnerInfo
    // isLoading,
  } = props;

  // console.log('ShareFileDownload.js-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  const { bucketName } = store.bucketObjectStore;

  const { gLoading } = store.uiStore;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);




  let shareFileStopShareBody;

  shareFileStopShareBody = (
    <button className={classes.objectActionListItem}
      name="share-stop"
      disabled={isLoading}
      onClick={(event) => { 
        if (!isLoading) {
          // fileDownloadHandler(shareOwnerInfo.userId, object.Key, 3600, object); 
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.trashMark} {t('shareList.02', 'stop share')}
    </button>
  );
  


  return (
    <Fragment>
      {shareFileStopShareBody}
    </Fragment>
  );
}

export default ShareFileStopShare;

