import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import _, { result } from 'lodash';
// import axios from 'axios';

import { useStore } from '../../hook-store/store';

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
// import * as objectAccessUtils from '../../../utils/bucket/object-access-util';

import { SUBSCRIPTION_BACKEND_URL } from '../../App';


function GetSubscriptionData(props) {

  const [store, dispatch] = useStore();
  const { freeSizeGB } = store.bucketObjectStore;
  const { subscriptions, subscriptionConfig } = store.stripeData;

  const gLoading = store.uiStore.gLoading;


  useEffect(() => {
    getSubscriptionConfig();
  },[]);
  
  useEffect(() => {
    const activeSubscription = subscriptions.find(subscription => {
      return subscription.status === 'active';
    });
  
    if (activeSubscription) {
      const subLimitGB = Number(activeSubscription.metadata.storeSizeGB);
      
      if (subLimitGB > 0) {
        dispatch('SET_LIMITGB', subLimitGB);
      }

    } else {
      dispatch('SET_LIMITGB', freeSizeGB);
    }

  },[subscriptions]);

  useEffect(() => {
    if (subscriptionConfig && subscriptionConfig.length > 0) {
      setSubscriptionSizes(subscriptionConfig);
    }

  },[subscriptionConfig]);


  const getSubscriptionConfig = async () => {
    try {
      const result = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/subscription-config', {
        // method: 'GET',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
        }
      );

      const resData = await result.json();
      
      console.log('subscription config', result, resData);
      
      if (resData.data) {
        dispatch('SET_SUBSCRIPTIONCONFIG', resData.data);
      }

      return resData;

    } catch(err) {
      console.log(err);
      throw err;
    }
  };

  const setSubscriptionSizes = (subConfig) => {

    for (const sub of subConfig) {
      if (sub.name === 'mini') {
        dispatch('SET_MINISIZEGB', sub.storeSizeGB);
      }

      if (sub.name === 'basic') {
        dispatch('SET_BASICSIZEGB', sub.storeSizeGB);
      }

      if (sub.name === 'dev02') {
        dispatch('SET_DEV02SIZEGB', sub.storeSizeGB);
      }
      
    }
 
  };


  return (
    <Fragment>
    </Fragment>
  );
}

export default GetSubscriptionData;
