import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import './App.css';
import { Redirect, Navigate, useLocation } from 'react-router-dom';
import i18next from 'i18next';

import Backdrop from '../../../components/Backdrop/Backdrop';
import CancelModalContent from '../../../components/subscription/cancel/CancelModalContent';
// import Card from '../../../components/ui/Card';
import Loader from '../../../components/Loader/Loader';
// import ModalSimple from '../../../components/modal/ModalSimple';
import ModalConfirm from '../../../components/Modal/ModalConfirm';
import { useStore } from '../../../hook-store/store';

import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from '../../../App';

import classes from './Cancel.module.css';

const Cancel = (props) => {
  // console.log('Cancel.js-props', props);
  const location = useLocation();
  // console.log('location in Cancel.js', location);
  const priceData = location.state.priceData;

  const [store, dispatch] = useStore();
  const { 
    totalSizeGB,
    freeSizeGB,
   } = store.bucketObjectStore;

  const [cancelled, setCancelled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  let exceedFreeSizeGB = false;

  if (totalSizeGB > freeSizeGB) {
    exceedFreeSizeGB = true;
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // const { subscription, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/cancel-subscription', {
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + localStorage.getItem('token'),
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     subscriptionId: location.state.subscription
    //   }),
    // })

    // if (error) {
    //   // console.log(error);
    //   setIsLoading(false);
    //   return;
    // }
    // if (!error) {
    //   // console.log(subscription);
    //   setIsLoading(false);
    //   setCancelled(true);
    // }

    const result = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/cancel-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: location.state.subscription,
        customerId: localStorage.getItem('customerId'),
        priceId: priceData.id,
      }),
    })

    const resData = await result.json();

    console.log('resData', result, resData);

    if (!result.ok) {
      // console.log(error);
      setIsLoading(false);

      setErrorMessage('Cancel failed');

      return;

    } else {
      // console.log(subscription);
      setIsLoading(false);
      setCancelled(true);
    }

    dispatch('SHOW_NOTIFICATION', {
      status: 'success',
      title: 'Unsubscribed',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION')
    }, 1000*5);
  };

  if(cancelled) {
    // return <Redirect to={`/subscription/account`} />
    return <Navigate to={`/subscription/account`} />

    // return <Redirect to={`/`} />
  }

  return (
    <div>
      {showCancelModal &&
        <div>
          <Backdrop />
          <ModalConfirm 
            hideButtons={true}
            onClose={() => { setShowCancelModal(false); }}
          >
            <CancelModalContent 
              setShowCancelModal={setShowCancelModal}
              handleClick={handleClick}
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          </ModalConfirm>
        </div>
        }

      {/* <Card> */}
      <div className="subscriptionContent">
        <div className={classes.subscriptionCardContents}>
          <h2>
            {/* Cancel */}
            {i18next.t('generalText1', 'Cancel')}
          </h2>
          <div className={classes.subscriptionCancelContents}>
            {/* Type: {priceData && priceData.product.name} */}
            {i18next.t('subText4', 'Type')}: {priceData && priceData.product.name}
          </div>
          <Link
            to={{
              pathname: "/subscription/account",
              // state: { 
              //   subscription: props.subscription.id, 
              //   data: props.subscription,
              //   priceData: priceData,
              //  },
            }}
          >
            <button className={classes.subscriptionCancelButton}
              disabled={isLoading}
            >
              {/* Go Back */}
              {i18next.t('subText12', 'Go Back')}
            </button>
          </Link>
          <button className={classes.subscriptionButton}
            // disabled={isLoading || exceedFreeSizeGB}
            disabled={isLoading || exceedFreeSizeGB}
            onClick={() => {
              setShowCancelModal(!showCancelModal);
              setErrorMessage(''); 
            }} 
          >
            {/* Cancel Subscription */}
            {i18next.t('subText10', 'Cancel Subscription')}
          </button>
          
          {exceedFreeSizeGB && (
            <div>
              Total file sizes are more than limit of without subscription (100 MB).
              {' '} 
              File deletion is required to cancel.
            </div>
          )}

          {isLoading && <div><Loader /></div>}
        </div>
      </div>
      {/* </Card> */}
    </div>
  )
}


export default Cancel;
