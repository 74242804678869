import { Fragment } from "react";
import { Link } from "react-router-dom";
// import { useEffect, useState } from 'react';
// import { BrowserRouter as Switch, Route, useHistory, Link } from "react-router-dom";

// import BucketObjectAllList from './components/BucketObjectAllList';
import { useStore } from "../../hook-store/store";

import { loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import classes from './AuthRequired.module.css';

function AuthRequired() {
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;

  return (
    <Fragment>
      <div className={classes.authRequired}>
      <div>
          Login is required to access files, or create account
        </div>
        <div className={classes.authRequiredButtons}>
          <a href={loginPageLink}>
            <button className="btnBase">
              {marks.signInMrak} Login
            </button>
          </a>
          <span> or </span>
          <a href={signupPageLink}>
            <button className="btnBase">
              {marks.userPlusMrak} Signup
            </button>
          </a>
        </div>

        {/* <div className={classes.authRequiredButtons}>
          <Link to="/image-editor">
            <button className="btnBase">
            {marks.paintBrushMark} Go to image editor page
            </button>
          </Link>
        </div> */}

        <div className={classes.authRequiredAboutLink}>
          <Link to="/about">
            About Kura Storage
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default AuthRequired;
