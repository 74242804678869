export const getShareNotifications = async (url, token) => {
  try {
    const result = await fetch(url + `/share-notification/share-notifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({})
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const updateShareNotification = async (url, token, entryKey, entryValue) => {
  try {
    const result = await fetch(url + `/share-notification`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        entryKey: entryKey,
        entryValue: entryValue,
      })
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const deleteShareNotification = async (url, token, notificationId) => {
  try {
    const result = await fetch(url + `/share-notification/share-notification?notificationId=${notificationId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({})
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};