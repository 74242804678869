import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


// import Loader from "../../Loader/Loader";

import { useStore } from "../../../hook-store/store";
import { 
  getUserShareMetaInfoList,
  getShareOwnerInfoList,
  getSharingUserInfoList,
} from '../../../utils/share-bucket/share-bucket-util';
import {
  createSharingObjectList
} from '../../../utils/share-bucket/share-bucket-object-util';
import {
  getShareNotifications
} from '../../../utils/share-bucket/share-notification-util';

import { BACKEND_URL } from "../../../App";


const GetShareInfo = (props) => {
  // const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  const { 
    allBucketObjects,
    objectAction,
  } = store.bucketObjectStore;
  const { allObjectsMetaList } = store.objectMetadataStore;
  const { 
    shareObjects,
    sharingUsers,
  } = store.shareObjectStore;
  const { 
    shareNotification,
    shareNotificationOpenTime,
  } = store.shareObjectStore;

  const [isShareOwnersObtain, setIsShareOwnersObtain] = useState(false);
  const [isSharingUsersObtain, setIsSharingUserObtain] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (isAuth && !isShareOwnersObtain) {
      if (shareObjects.length === 0) {
        getUserShareMetaHandler();
      }
    }
  },[isAuth]);



  useEffect(() => {
    if (isAuth && !isSharingUsersObtain) {
      if (allBucketObjects.length > 0 && sharingUsers.length === 0) {
        getSharingUsersHandler();
      }
    }
  },[isAuth, allBucketObjects]);


  useEffect(() => {
    if (allBucketObjects.length > 0 && allObjectsMetaList.length > 0) {
      const sharingObjects = createSharingObjectList(
        allObjectsMetaList,
        allBucketObjects,
      );
      
      if (sharingObjects.length > 0) {
        dispatch('SET_SHARINGOBJECTS', sharingObjects);
      }
      console.log('sharingObjects', sharingObjects);
    }
  },[allBucketObjects, allObjectsMetaList]);


  useEffect(() => {
    if (isAuth) {
      getShareNotificationsHandler();
    }
  },[isAuth]);


  useEffect(() => {
    if (shareNotification) {
      const nonOpenNotification = notOpenNotificationExist(
        shareNotification, 
        shareNotificationOpenTime,
      );
      
      dispatch('SET_NOTOPENSHARENOTIFICATIONEXIST', nonOpenNotification);
    }
  },[shareNotification]);


  useEffect(() => {
    if (objectAction && 
      objectAction.actionType === 'tools-share-file-update-notification'
    ) {
      getUserShareMetaHandler();
    }
  },[objectAction]);


  const notOpenNotificationExist = (shareNotification, localLastOpenTime) => {
    console.log('in notOpenNotificationExist notify')
    const notOpenList = [];

    for (const notify of shareNotification.shareNotifications) {
      if (notify.time > shareNotification.lastOpenTime) {
        console.log('notify.time', notify.time, shareNotification.lastOpenTime)
        notOpenList.push(notify);
      }

    }

    if (notOpenList.length > 0) {
      return true;
    }

    return false;
  };


  const getUserShareMetaHandler = async () => {
    try { 
      setIsLoading(true);
      dispatch('SET_SHAREOBJECTLOADING', true);

      const resData = await getUserShareMetaInfoList(
        BACKEND_URL, 
        localStorage.getItem('token'),
      )

      console.log(resData);

      if (resData.data && resData.data.length > 0) {
        dispatch('SET_SHAREOBJECTS', resData.data);

        getShareOwnersHandler();
      }
  
      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);
    }
  };



  const getShareOwnersHandler = async () => {
    try { 
      setIsLoading(true);
      dispatch('SET_SHAREOBJECTLOADING', true);

      const resData = await getShareOwnerInfoList(
        BACKEND_URL,
        localStorage.getItem('token'),
      );

      console.log(resData);
      
      if (resData?.data?.ownerInfoList?.length > 0) {

        const ownerInfoList = [];

        for (const shareOwnerInfo of resData.data.ownerInfoList) {

          let userNameData;

          if (resData.data.ownerUserNameDataList?.length > 0) {
            userNameData = resData.data.ownerUserNameDataList.find(element => {
              return element.userId === shareOwnerInfo.userId;
            });
          }

          ownerInfoList.push({
            ...shareOwnerInfo,
            userNameData: userNameData,
          });
        }

        dispatch('SET_SHAREOWNERS', ownerInfoList);
        setIsShareOwnersObtain(true);
      }

      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);
    }
  };


  const getSharingUsersHandler = async () => {
    try { 
      setIsLoading(true);
      dispatch('SET_SHAREOBJECTLOADING', true);

      const resData = await getSharingUserInfoList(
        BACKEND_URL,
        localStorage.getItem('token'),
      );

      console.log(resData);

      if (resData.data) {
        if (resData.data.sharingUserInfoList?.length > 0) {
          
          const shareUserInfoList = [];

          for (const shareUserInfo of resData.data.sharingUserInfoList) {

            let userNameData;

            if (resData.data.sharingUserUserNameDataList?.length > 0) {
              userNameData = resData.data.sharingUserUserNameDataList.find(element => {
                return element.userId === shareUserInfo.userId;
              });
            }

            shareUserInfoList.push({
              ...shareUserInfo,
              userNameData: userNameData,
            });
          }

          dispatch('SET_SHARINGUSERS', shareUserInfoList);
          // dispatch('SET_SHARINGUSERS', resData.data.sharingUserInfoList);
        }

        // if (resData.data.sharingUserUserNameDataList?.length > 0) {
        //   dispatch('SET_SHARINGUSERNAMEDATALIST', resData.data.sharingUserUserNameDataList);
        // }

        setIsSharingUserObtain(true);
      }
      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_SHAREOBJECTLOADING', false);
    }
  };


  const getShareNotificationsHandler = async () => {
    try { 
      // setIsLoading(true);

      const resData = await getShareNotifications(
        BACKEND_URL,
        localStorage.getItem('token'),
      );

      console.log(resData);

      if (resData.data && resData.data.shareNotifications &&
        resData.data.shareNotifications.length > 0) {
        dispatch('SET_SHARENOTIFICATION', resData.data);
      }
      // setIsLoading(false);
      return resData;

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };

  return <Fragment></Fragment>;
};

export default GetShareInfo;
