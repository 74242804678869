import React, { Fragment, useEffect, useState } from 'react';

// import openSocket from 'socket.io-client';
import { io } from "socket.io-client";

import { useStore } from '../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'

import { BACKEND_URL } from '../../App';

// import classes from './PostSelect.module.css';
// export let socket;

function UserSocketConnect(props) {
  // const { } = props;

  const [store, dispatch] = useStore();
  // const { allBucketObjects } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  // const { shareObjects } = store.shareObjectStore;
  // const { bucketName, currentPrefix } = store.bucketObjectStore;
  // const { isAuth } = store.uiStore;

  // const [shareObjectUpdateData, setShareObjectUpdateData] = useState();

  useEffect(() => {
      // const socket = openSocket(BACKEND_URL);
      const socket = io(BACKEND_URL, {
        // autoConnect: false
      });

      const userId = localStorage.getItem('userId');

      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
        // dispatch('SET_SOCKETID', socket.id);

        socket.emit('user-id', {
          socketId: socket.id,
          userId: userId,
          token: localStorage.getItem('token'),
        });
      });

      socket.on("casauthsso-user-name-data-update", (data) => {
        console.log('casauthsso-user-name-data-update data', data);
        // dispatch('SET_SOCKETID', socket.id);
        if (data?.userNameData?.userId === localStorage.getItem('userId')) {
          dispatch('SET_USERNAMEDATA', data.userNameData);
        }
      });

  },[]);


  return (
    <Fragment></Fragment>
  );
}

export default UserSocketConnect;