import React, { useState, useEffect } from "react";
import { Link, withRouter, Redirect, Navigate } from "react-router-dom";
import i18next from "i18next";

// import './App.css';

import AccountSubscriptionList from "../../../components/subscription/subscriptionList/AccountSubscriptionList";
// import Language from './components/language/Language';
import Loader from "../../../components/Loader/Loader";
// import Card from "../../../components/ui/Card";
import Prices from "../../../components/subscription/prices/Prices";
import { useStore } from "../../../hook-store/store";
import { getCustomerSubscriptions, getCurrentSubscription } from "../../../utils/stripe";

import classes from "./Account.module.css";

const Account = () => {
  // document.title = 'Kura Storage subscription page';
  
  const lsCustomerId = localStorage.getItem("customerId");
  const lsCustomerEmail = localStorage.getItem("customerEmail");
  const lsToken = localStorage.getItem("token");

  const [state, dispatch] = useStore();
  // console.log(state);

  // const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const subscriptions = state.stripeData.subscriptions;
  const currentSubscription = getCurrentSubscription(subscriptions);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getCustomerSubscriptions(lsCustomerId, lsToken, lsCustomerEmail)
      .then((result) => {
        // console.log(result);
        if (result.data.length > 0) {
          // dispatch("SET_SUBSCRIPTIONS", result.data);

          console.log('in getCustomerSubscriptions');
          const remeetSubs = result.data.filter(sub => {
            return sub.metadata.service === 'kura-storage';
          })

          dispatch('SET_SUBSCRIPTIONS', remeetSubs);

          if (remeetSubs.length === 0) {
            dispatch("SET_SUBSCRIPTIONS", []);
          }
        } else {
          dispatch("SET_SUBSCRIPTIONS", []);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);

        setIsLoading(false);
      });
  }, []);

  // const inactiveStatuses = [];
  // const trialStatuses = [];
  const subStatuses = [];
  if (subscriptions && subscriptions.length > 0) {
    for (const sub of subscriptions) {
      // console.log(sub.status);
      subStatuses.push(sub.status);
      // if (sub.status === 'trialing') {
      //   trialStatuses.push({
      //     id: sub.id,
      //     status: sub.status
      //   })
      // }
      // if (sub.status === 'past_due') {
      //   // setInactiveSubExist(true);
      //   inactiveStatuses.push({
      //     id: sub.id,
      //     status: sub.status
      //   });
      // }
    }
  }

  // console.log(subStatuses);

  if (!subscriptions) {
    return "";
  }

  if (!lsCustomerId) {
    // return <Redirect to={`/`} />;
    return <Navigate to={`/`} />;
  }

  if (isLoading) {
    return (
      <div className={classes.subscriptionLoader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="subscriptionContent">
      {/* <Card> */}
        <div className={classes.subscriptionCardContents}>
          <h2>
            {/* Account  */}
            {i18next.t("subText1", "Account")}
          </h2>
          <div>
            {/* Email address: {lsCustomerEmail} */}
            {i18next.t("subText2", "Email Address")}: {lsCustomerEmail}
          </div>

          {/* <a href="/prices">Add a subscription</a> */}

          <div
          // onClick={stripeLogout}
          >
            <Link to="/sizemanage">
              {i18next.t("subText12", "Go Back")}
            </Link>
          </div>
          {/* <a href="/" onClick={logout}>
            Logout
            {i18next.t('generalText14', 'Logout')}
          </a> */}

          {!currentSubscription && <div>no active subscription</div>}

        </div>
      {/* </Card> */}

      {/* <Card> */}
        <div className={classes.subscriptionCardContents}>
          {subStatuses.includes("trialing") ||
          subStatuses.includes("past_due") ||
          // || subStatuses.includes('canceled')
          // || subStatuses.includes('unpaid')
          subStatuses.includes("active") ? (
            <AccountSubscriptionList subscriptions={subscriptions} />
          ) : (
            <Prices subscriptions={subscriptions} />
          )}

          {/* <Prices subscriptions={subscriptions} /> */}
        </div>
      {/* </Card> */}

      {/* {!isLoading && <Language />} */}
    </div>
  );
};

export default Account;
