import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';

// import TrashMenuForWide from './TrashMenu/TrashMenuForWide';
// import TrashPrefixLine from './TrashPrefixLine';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalConfirm from '../../../Modal/ModalConfirm';
import TrashExistKeysList from './TrashExistKeysList';

import { useStore } from '../../../../hook-store/store';
// import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
// import { 
//   // updateTrashMetadata, 
//   deleteFileTrashMetadata,
//   deleteFolderTrashMetadata,
//   getObjectMetadata,
//   getMetadataFromMetaList,
//   getParsedMetadataFromObjectMeta,
//   createParentPrefixList,
//  } from '../../../../utils/bucket/object-metadata-util';

// import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

// import classes from '../BucketObject/BucketObjectList.module.css';
// import { createDeletedObjectList } from '../../../utils/bucket/file-move-util';
// import { update } from 'lodash';

// import ObjectControl from '../ObjectControl/ObjectControl';

function TrashExistKeys(props) {

  const {
    // trashObjects,
    selectedTrashObjects,
    existDestKeys,
    setExistDestKeys,
    deleteTrashboxMetaHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  // const { 
  //   allBucketObjects,
  //   bucketName,
  // } =store.bucketObjectStore;

  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;

  // const [existDestKeys, setExistDestKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  // //// reset existDesKeys when trashObjects changed
  // useEffect(() => {
  //   setExistDestKeys([]);
  // },[trashObjects, selectedTrashObjects]);




  let trashExistKeysBody

  if (existDestKeys.length > 0) {

    trashExistKeysBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          onCancel={() => { 
            if (!isLoading) {
              setExistDestKeys([]);
            } 
          }}
        />
        <ModalConfirm
          hideButtons={true}
          onClose={() => { 
            if (!isLoading) {
              setExistDestKeys([]);
            } 
          }}
          onAccept={() => {
            if (!isLoading) {
              deleteTrashboxMetaHandler(
                selectedTrashObjects, 
                allObjectsMetaList, 
              );
            }
          }}
        >
          <TrashExistKeysList 
            existDestKeys={existDestKeys}
          />
        </ModalConfirm>
    </div>
   );
  }

  return (
    <Fragment>
      <div>{trashExistKeysBody}</div>
    </Fragment>

   
  );
}

export default TrashExistKeys;
