import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// import TrashMenuForWide from './TrashMenu/TrashMenuForWide';
// import TrashPrefixLine from './TrashPrefixLine';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalConfirm from '../../../Modal/ModalConfirm';
import TrashExistKeys from './TrashExistKeys';

import { useStore } from '../../../../hook-store/store';
// import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
// import { 
//   // updateTrashMetadata, 
//   deleteFileTrashMetadata,
//   deleteFolderTrashMetadata,
//   getObjectMetadata,
//   getMetadataFromMetaList,
//   getParsedMetadataFromObjectMeta,
//   createParentPrefixList,
//  } from '../../../../utils/bucket/object-metadata-util';

// import { BACKEND_URL } from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './TrashSelectedControl.module.css';

function TrashSelectedControl(props) {

  const {
    trashObjects,
    selectedTrashObjects,
    setTrashObjects,
    setSelectedTrashObjects,
    deleteTrashboxMetaHandler,
    deleteFilesFoldersHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const { 
    allBucketObjects,
    bucketName,
  } =store.bucketObjectStore;

  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;

  const [existDestKeys, setExistDestKeys] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  //// reset existDesKeys when trashObjects changed
  useEffect(() => {
    setExistDestKeys([]);
    setShowDeleteConfirm(false);
    setShowRestoreConfirm(false);
  },[trashObjects, selectedTrashObjects]);



  const getExistDestKeys = (restoreList, allBucketObjects) => {

    const eDestKeys = [];

    for (const object of restoreList) {
      const trashYIndex = object.Key.indexOf('Y');
      const destKey = object.Key.substring(trashYIndex + 2);
      
      const isDestKeyExist = allBucketObjects.find(element => {
        return element.Key === destKey;
      });
  
      if (isDestKeyExist) {
        eDestKeys.push(destKey);
      }
    }

    const uniqList = _.uniq(eDestKeys);

    // setExistDestKeys(uniqList);
    // return uniqList;

    if (uniqList.length > 0) {
      setExistDestKeys(uniqList);
      return uniqList;

    } else {
      setShowRestoreConfirm(true);
    }

  };


  const deleteConfirmModalBody = (
    <div>
      <Backdrop 
        zIndex={'110'}
        onCancel={() => { 
          if (!isLoading) {
            setShowDeleteConfirm(false);
          } 
        }}
      />
      <ModalConfirm
        hideButtons={false}
        onClose={() => { 
          if (!isLoading) {
            setShowDeleteConfirm(false);
          } 
        }}
        onAccept={() => { 
          if (!isLoading) {
            deleteFilesFoldersHandler(
              bucketName, 
              selectedTrashObjects,
              trashObjects,
            );
          } 
        }}
      >
        {t('trash.07', 'Delete')}?
      </ModalConfirm>
    </div>
  );

  const restoreConfirmBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          onCancel={() => { 
            if (!isLoading) {
              setShowRestoreConfirm(false);
            } 
          }}
        />
        <ModalConfirm
          hideButtons={false}
          onClose={() => { 
            if (!isLoading) {
              setShowRestoreConfirm(false);
            } 
          }}
          onAccept={() => {
            if (!isLoading) {
              deleteTrashboxMetaHandler(
                selectedTrashObjects, 
                allObjectsMetaList, 
              );
            }
          }}
        >
          <div>{t('trash.06', 'restore from trash')} ?</div>
        </ModalConfirm>
    </div>
  );



  let trashControlBody;

  if (selectedTrashObjects.length > 0) {
    trashControlBody = (
      <div className={classes.trashSelectedControl}>
        <div></div>
        <div className={classes.trashSelectedContainer}>
          <div className={classes.trashSelectedTitle}>
            {t('trash.04', 'trash action')}
          </div>
          <div className={classes.trashSelectedTitleSeparator}></div>
          <div className={classes.trashActionItemContainer}>
            <div className={classes.trashActionItem}
              onClick={() => {
                if (!isLoading) {
                  getExistDestKeys(selectedTrashObjects, allBucketObjects);
                }
              }}
            >
              {marks.moveMark} {t('trash.06', 'restore from trash')}
            </div>
            <div className={classes.trashActionItem}
              onClick={() => {
                if (!isLoading) {
                  setShowDeleteConfirm(true);
                }
              }}
            >
              {marks.trashMark} {t('trash.07', 'delete')}
            </div>
          </div>
  
        </div>
      </div>
    );
  }



  return (
    <Fragment>
      <div>{trashControlBody}</div>

      <div>
        <TrashExistKeys 
          // trashObjects={trashObjects}
          selectedTrashObjects={selectedTrashObjects}
          existDestKeys={existDestKeys}
          setExistDestKeys={setExistDestKeys}
          deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
        />
      </div>

      {showDeleteConfirm && (
        <div>{deleteConfirmModalBody}</div>
      )}

      {showRestoreConfirm && (
        <div>{restoreConfirmBody}</div>
      )}
    </Fragment>

   
  );
}

export default TrashSelectedControl;
