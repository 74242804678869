import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import numeral from 'numeral';
// import axios from 'axios';

import { useStore } from '../../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import Backdrop from '../../../Backdrop/Backdrop';
import ModalConfirm from '../../../Modal/ModalConfirm';
import ModalPosition from '../../../Modal/ModalPosition';
import SizesSummary from '../../SizesContent/SizesSummary';
import SizesContent from '../../SizesContent/SizesContent';

// import { BACKEND_URL } from '../../../App';

import { marks } from '../../../../utils/marks';
// import classes from './TrashMenu.module.css';
import classes from '../../ObjectControl/ObjectControl.module.css';

function TrashMenu(props) {

  const {
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const {
    bucketName, 
    showObjectControl,
    bucketSizeGB,
    totalSizeGB,
    limitGB,
   } = store.bucketObjectStore;
  // const { subscriptions } = store.stripeData;
  const { gLoading, isAuth } = store.uiStore;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
 
  const [modalPositions, setModalPositions] = useState({ 
    top: null, 
    left: null,
    right: null,
    bottom: null,
    heightLimit: 300,
  });

  // const [showObjectControl, setShowObjectControl] = useState(false);
  const [showDropArea, setShowDropArea] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showSizesSummary, setShowSizesSummary] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    showObjectControlHandler(false);
  },[]);



  const showObjectControlHandler = (value) => {
    // setShowObjectControl(value);
    dispatch('SET_SHOWOBJECTCONTROL', value);

    const targetEl = document.getElementById(`object-control`);
    const targetRect = targetEl.getBoundingClientRect();

    console.log(targetEl);
    console.log(targetRect);

    console.log(targetRect.bottom, window.innerHeight - targetRect.bottom, window.innerHeight)
    
    // const heightLimit = 300;

    setModalPositions({
      ...modalPositions,
      top: targetRect.bottom,
      heightLimit: window.innerHeight - targetRect.bottom,
    })

  };



  

 


  if (!isAuth || !bucketName || window.location.pathname !== '/') {
    return null;
  }

  return (
    <Fragment>
      <div className={classes.objectControll}
        id="object-control"
      >
        <span className={classes.objectControlButton}
          onClick={() => {
            if (!isLoading) {
              showObjectControlHandler(true);
            }
          }}
        >
          {t('trash.11', 'Menu')} 
        </span>
      </div>

      {showObjectControl && (
        <div>
          <Backdrop 
            zIndex={'90'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              if (!isLoading) {
                // setClickedObjectKeyHandler(''); 
                showObjectControlHandler(false);
              }
            }}
          />
          <ModalPosition 
            top={modalPositions.top}
            left={modalPositions.left}
            right={modalPositions.right}
            bottom={modalPositions.bottom}
            heightLimit={modalPositions.heightLimit}
            position='absolute'
            zIndex='100'
            // onClose={() => {
            //   if (!isLoading) {
            //     showObjectControlHandler(false);
            //   }
            // }}
          >
            <div className={classes.objectControlModal}>
              {/* <div className={classes.modalCloseMark}>
                <span className={classes.modalCloseMarkButton}
                  onClick={() => {
                    if (!isLoading) {
                      showObjectControlHandler(false);
                    } 
                  }}
                >
                  {marks.closeMark}
                </span>
              </div> */}
              {/* <div>
                object-control-modal
              </div> */}
              
              <div className={classes.objectControlListItem}>
                <span
                  onClick={() => { 
                    if (!isLoading) {
                      dispatch('SET_DISPLAYTABLE', ''); 
                      showObjectControlHandler(false);
                    }
                  }}
                >
                  {marks.tableMark} {t('trash.12', 'files')}
                </span>
              </div>

              {/* <div className={classes.objectControlListItem}
                onClick={() => { 
                  if (!isLoading) {
                    dispatch('SET_DISPLAYTABLE', 'trashbox'); 
                    // showObjectControlHandler(false);
                  }
                }}
              >
                trash-box
              </div> */}
              
              <div className={classes.objectControlListItem}>
                <SizesContent
                  setShowSizesSummary={setShowSizesSummary}
                />
              </div>
            </div>
          
          </ModalPosition>
        </div>
      )}
    

      {showSizesSummary && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { 
              if (!isLoading) {
                setShowSizesSummary(false);
              }
            }}
          />
        <ModalConfirm 
        hideButtons={true}
        onClose={() => { 
          if (!isLoading) {
            setShowSizesSummary(false);
          } 
        }}
        >
          <SizesSummary />
        </ModalConfirm>
        </div>
      )}

    </Fragment>
  );
}

export default TrashMenu;
