import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ModalConfirm from '../../Modal/ModalConfirm';
import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import { BACKEND_URL } from '../../../App';

function SelectedObjectsDelete(props) {
  // const {  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedPrefix = store.bucketObjectStore.selectedPrefix;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const gLoading = store.uiStore.gLoading;

  // const [showFoldersModal, setShowFoldersModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const deleteFilesFoldersHandler = async (bucket, prefixes, keys, topLevels) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileDelete: null,
        folderDelete: null,
      }

      console.log('delete file folder num', keys.length, prefixes.length);

      let deletedList;

      if (keys.length > 0) {
        // await deleteBucketObjectsHandler(bucket, keys);

        if (!bucket || !keys || keys.length === 0) {
          throw new Error('bucket and keys are required');
        };
  
        console.log(keys, bucket);
        const resData = await bucketObjectUtils.deleteBucketObjects(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          keys,
        );
  
        console.log(resData);
        
        resultsForAction.fileDelete = resData;
  
        deletedList = fileMoveUtils.createDeletedObjectList(keys, topLevels);
        dispatch('SET_TOPLEVELS', deletedList);
        dispatch('SET_SELECTED_FILEKEYS',[]);

      }

      if (prefixes.length > 0) {

        const result = await bucketObjectUtils.deleteFolders(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          prefixes,
        );
  
        console.log(result);
           
        resultsForAction.folderDelete = result;

        let forTop = topLevels;
        
        if (deletedList) {
          forTop = deletedList
        }

        const deletedList2 = fileMoveUtils.createFoldersDeletedObjectList(
          prefixes, 
          forTop, 
          currentPrefix
        );

        dispatch('SET_TOPLEVELS', deletedList2);
        dispatch('SET_MOVEPREFIXES', []);
  
      }

      dispatch('SET_SELECTED_PREFIX', '');


      dispatch('SET_OBJECTACTION', {
        actionType: 'delete-selected-objects',
        delete: true,
        add: false,
        result: resultsForAction,
      });


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'deletion success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      
      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `deletion failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


















  let selectedObjectsDeleteBody;

  selectedObjectsDeleteBody = (
    <div>
      <ModalConfirm 
        onClose={() => { 
          dispatch('SET_OBJECTS_ACTIONTYPE', '');
        }}
        onAccept={() => { 
          deleteFilesFoldersHandler(
            bucketName, 
            movePrefixes, 
            selectedFileKeys,
            topLevels, 
          );  
        }}
        isLoading={isLoading}
      >
        {t('selectedObject.02', 'delete')} ? 
      </ModalConfirm>
      
      {/* <div>
        <button 
          disabled={isLoading}
          onClick={() => { 
            if (!isLoading) {
              setShowDeleteModal(true);
            }
          }}
        >
          delete-selected-files-folders
        </button>
      </div> */}
    </div>
  );


  
  return (
    <Fragment>
      {selectedObjectsDeleteBody}
    </Fragment>
  );
}

export default SelectedObjectsDelete;
