import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isExpired, decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";

import Loader from "../../Loader/Loader";

import { useStore } from "../../../hook-store/store";
import { 
  getUserShareMetaInfoList,
  updateShareMetadataAcceptId,
  getShareOwnerInfoList,
} from '../../../utils/share-bucket/share-bucket-util';

import { BACKEND_URL } from "../../../App";


import ShareObjectList from "./ShareObjectList/ShareObjectList";

const ShareProcess = (props) => {
  // const [t] = useTranslation('translation');
  const  navigate = useNavigate();

  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;
  
  const token = queryParams.get('token');


  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  // const { shareObjects } = store.shareObjectStore;

  const [shareData, setShareData] = useState();
  const [tokenExpire, setTokenExpire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuth && shareData && shareData.ownerId !== localStorage.getItem('userId')) {
      shareProcessHandler(
        shareData.bucket,
        'add',
        shareData.userId,
      );
    }
  },[isAuth, shareData]);

  useEffect(() => {
    if (token) {
      const isExpiredToken = isExpired(token);
      
      if (isExpiredToken) {
        setTokenExpire(true);
      }

      if (!isExpiredToken) {
        const decodedToken = decodeToken(token);
        console.log('decodedToken', decodedToken)
        if (decodedToken.userId === localStorage.getItem('userId')) {
          setShareData(decodedToken);
        } else {
          console.log('userId is different', decodedToken.userId, localStorage.getItem('userId'));
        }

        // console.log('decoded share token', decodeToken(token))
      }
    }

  },[token]);



  const shareProcessHandler = async (bucket, action, userId) => {
    try { 
      setIsLoading(true);

      const result = await fetch(BACKEND_URL + '/share-bucket/update-share-bucket-data', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          bucket: bucket,
          action: action,
          userId: userId,
        })
      });
  
      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(result, resData);

      if (resData.data && resData.data.shareBuckets && 
        resData.data.shareBuckets.length > 0
      ) {
        addShareAcceptIdHandler(
          shareData.bucket, 
          shareData.key,
          shareData.userId,
        );
      }
  
      setIsLoading(false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'share file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 5);
    }
  };



  const addShareAcceptIdHandler = async (bucket, key, userId) => {
    try { 
      setIsLoading(true);

      const resData = await updateShareMetadataAcceptId(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        userId,
        'add',
      );


      // const resData = await result.json();
      console.log('share addAcceptId-resData', resData);

      if (resData) {
        // getUserShareMetaHandler(shareData.userId);

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'share file success. File will be found in menu on top page',
          message: '',
        });
  
        setTimeout(() => {
          // dispatch('CLEAR_NOTIFICATION');
          navigate('/');
          window.location.reload();
        },1000 * 5);

      }
      
      // if (resData.data && resData.data.shareBuckets && 
      //   resData.data.shareBuckets.length > 0
      // ) {
      //   // getUserShareMetaHandler();

      // }
  
      setIsLoading(false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      if (err.message === 'id-not-found-in-writeIds-and-readIds') {
        dispatch('SHOW_NOTIFICATION', {
          status: 'error',
          title: 'share file failed, File owner might stop file sharing. If you need to share the file, please ask file owner.',
          message: '',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        },1000 * 60);

      } else {
        dispatch('SHOW_NOTIFICATION', {
          status: 'error',
          title: 'share file failed',
          message: '',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        },1000 * 10);
      }
    }
  };



  // const getUserShareMetaHandler = async () => {
  //   try { 
  //     setIsLoading(true);

  //     const resData = await getUserShareMetaInfoList(
  //       BACKEND_URL, 
  //       localStorage.getItem('token'),
  //     )

  //     console.log(resData);

  //     if (resData.data && resData.data.length > 0) {
  //       dispatch('SET_SHAREOBJECTS', resData.data);

  //       getShareOwnersHandler(resData.data);
  //     }
  
  //     setIsLoading(false);

  //     return resData;

  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };



  // const getShareOwnersHandler = async (shareObjects) => {
  //   try { 
  //     setIsLoading(true);

  //     const resData = await getShareOwnerInfoList(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //     );

  //     console.log(resData);

  //     if (resData.data && resData.data.length > 0) {
  //       dispatch('SET_SHAREOWNERS', resData.data);
  //     }
  //     setIsLoading(false);

  //     return resData;

  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };


  // const deleteShareAcceptIdHandler = async (bucket, key, userId) => {
  //   try { 
  //     setIsLoading(true);

  //     const resData = await updateShareMetadataAcceptId(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       key,
  //       userId,
  //       'delete',
  //     );

  //     console.log(resData);

  //     setIsLoading(false);

  //     return resData;

  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };



  return <Fragment>
    {/* share-process-component */}
      {isLoading && (
        <div><Loader /></div>
      )}

      {tokenExpire && (
        <div>Page expired. Please ask again to share file owner</div>
      )}


      {/* <ul>{shareObjects.map(object => {
        return (
        <li key={object.Key}>
          {object.Key}
          <button onClick={() => {
            deleteShareAcceptIdHandler(
              object.bucket,
              object.Key,
              localStorage.getItem('userId')
            );
          }}
          >
            delete-acceptId
          </button>
          <button onClick={() => { getUserShareMetaHandler() }}>
            get-share
          </button>
        </li>
        )
      })}</ul> */}

      {/* <ShareObjectList /> */}
  </Fragment>;
};

export default ShareProcess;
