// import { useContext } from 'react';
import React from 'react';
import { Fragment, useEffect } from 'react';
import Bowser from "bowser";

import { useWindowDimensions } from '../../custom-hooks/useWindowDimensions';

import { useStore } from '../../hook-store/store';

// import classes from './notification.module.css';
// import NotificationContext from '../../store/notification-context';

function GetWindowData(props) {
  // const notificationCtx = useContext(NotificationContext);
  
  const { setWindowValues } = props;

  const { height, width, scrollX, scrollY } = useWindowDimensions();
  // console.log(useWindowDimensions());
  const [store, dispatch] = useStore();


  useEffect(() => {
    // console.log(height, width);
    const values = {
      height: height,
      width: width,
      scrollX: scrollX,
      scrollY: scrollY,
    };
    // console.log('height, width in GetWindowData', height, width);
    // setWindowValues(values);
    dispatch('SET_WINDOW_VALUES', values);



    // if (store.uiStore.bowserData.platform.type === 'mobile' ||  width < 480 || height < 480) {
    //   dispatch('SET_ISSSCreen', true);
    // } else {
    //   dispatch('SET_ISSScreen', false);
    // }
    
  },[height, width]);

  useEffect(() => {
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // dispatch('SET_BOWSERDATA', browser.parsedResult);

    // if (browser.parsedResult.platform.type === 'mobile' || 
    // window.innerWidth < 480 || 
    // window.innerHeight < 480
    // ) {
    //   dispatch('SET_ISSScreen', true);
    // } else {
    //   dispatch('SET_ISSScreen', false);
    // }
  },[]);


  // const [store, dispatch] = useStore();
  // // console.log(store);

  return (
    <Fragment></Fragment>
  );
}

export default GetWindowData;