export const getShareDocxFile = async (url, token, bucket, key, mimeType) => {
  try {
    const result = await fetch(url + `/share-object-download/share-docx-file?bucket=${bucket}&key=${key}&mimeType=${mimeType}`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    console.log(result);

    // const resData = await result.blob();
    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
    throw err;
  }
};