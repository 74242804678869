import React, { useState, useEffect } from 'react';
// import { withRouter } from 'react-router-dom';
import { Redirect, Navigate, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import Backdrop from '../../Backdrop/Backdrop';
import Loader from '../../Loader/Loader';
// import ModalSimple from '../../modal/ModalSimple';
import PricesDetail from './PricesDetail';
// import TrialModalContent from './TrialModalContent';
import { useStore } from '../../../hook-store/store';
import { fetchPrices } from '../../../utils/stripe';

import { 
  BACKEND_URL, 
  SUBSCRIPTION_BACKEND_URL, 
  // subscriptionBasicPriceId,
  // subscriptionMiniPriceId ,
 } from '../../../App';


import classes from './Prices.module.css';

const Prices = (props) => {
  // console.log(props);
  const { hidePriceSelect } = props;

  const lsCustomerId = localStorage.getItem('customerId');
  const lsCustomerEmail = localStorage.getItem('customerEmail'); 
  const lsToken = localStorage.getItem('token');

  const navigate = useNavigate();

  const [store, dispatch] = useStore();
  // console.log(state);

  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [hasPastTrial, setHasPastTrial] = useState(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [selectedTrialId, setSelectedTrialId] = useState('');

  useEffect(() => {

    setIsLoading(true);

    fetchPrices()
      .then(res => {
        // console.log('fetchPrices in prices.js', res)
        // setPrices(res);
        setPrices(res.prices);
        dispatch("SET_PRICES", res.prices);
        setIsLoading(false);
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    const listCanceledSubscriptions = async (customerId, token, email) => {
      setIsLoading(true);
      // const {subscriptions} = await fetch(BACKEND_URL + '/subscriptions').then(r => r.json());

        const { subscriptions, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/list-customer-canceled-subscriptions', {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customerId: customerId,
            email: email,
          }),
        }).then(r => r.json());
        
        // console.log(subscriptions);
        
        if (error) {
          // console.log(error);
          setIsLoading(false);
        }

        if (subscriptions) {
          let trialNum = 0;

          const remeetSubs = subscriptions.data.filter(sub => {
            return sub.metadata.service === 'REMEET';
          });

          for (const sub of remeetSubs) {
            // console.log(sub.metadata);
            if (sub.metadata.trial) {
              trialNum = trialNum + 1;
            }
          }

          console.log(trialNum);
          if (trialNum === 0) {
            setHasPastTrial(false);
          }
          // setSubscriptions(subscriptions.data);
          setIsLoading(false);
        }
      }

      if (lsCustomerId) {
        listCanceledSubscriptions(lsCustomerId, lsToken, lsCustomerEmail);
      }
 
  }, []);


  const createSubscription = async (priceId) => {
    setIsLoading(true);

    const {
      subscriptionId, 
      clientSecret,
      // pendingClientSecret,
      error 
    } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/create-subscription', {
    // } = await fetch(BACKEND_URL + '/create-trial-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        customerId: lsCustomerId,
      }),
    }).then(r => r.json());



    // if (subscriptionId && (clientSecret || pendingClientSecret)) {
    if (subscriptionId && clientSecret) {
      setSubscriptionData({
        subscriptionId: subscriptionId, 
        clientSecret: clientSecret,
        // pendingClientSecret: pendingClientSecret
      });
      setIsLoading(false);
    }


    if (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }


  const createTrialSubscription = async (priceId) => {
    setIsLoading(true);

    const {
      subscriptionId, 
      clientSecret,
      pendingClientSecret,
      error 
    } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/create-trial-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        customerId: lsCustomerId,
      }),
    }).then(r => r.json());

    // console.log(sub);
    // console.log(subscriptionId, clientSecret, error,
    //   pendingClientSecret
    //   );
    
    if (subscriptionId && (clientSecret || pendingClientSecret)) {
    // if (subscriptionId && clientSecret) {
      setSubscriptionData({
        subscriptionId: subscriptionId, 
        clientSecret: clientSecret,
        pendingClientSecret: pendingClientSecret
      });
      setIsLoading(false);
    }

    if (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };



  if(subscriptionData && subscriptionData.pendingClientSecret) {
    // return <Redirect to={{
    //   pathname: '/subscribe-trial',
    //   state: subscriptionData
    // }} />
    return window.location.reload();
  }

  if(subscriptionData && subscriptionData.clientSecret) {
    // return <Redirect to={{
    //   pathname: '/subscription/subscribe',
    //   state: subscriptionData
    // }} />

    navigate("/subscription/subscribe", { 
      state: subscriptionData 
    });
  }

  return (
    <div>

      {/* {showTrialModal && 
        <div>
          <Backdrop />
          <ModalSimple>
            <TrialModalContent 
              setShowTrialModal={setShowTrialModal}
              createTrialSubscription={createTrialSubscription}
              selectedTrialId={selectedTrialId}
              isLoading={isLoading}
            />
          </ModalSimple>
        </div>
      } */}

      {!hidePriceSelect && (
        <h2>
          {/* Select a subscription type */}
          {i18next.t('subText13', 'Select a subscription type')}	
        </h2>
      )}

      <div className="price-list">
        {prices.map((price) => {
          // console.log(price.recurring.trial_period_days)
          return (
            <div key={price.id} className={classes.planContainer}>
              <h2>{price.product.name}</h2>

              <p className={classes.pricesPlanPrice}>
                {/* ${price.unit_amount / 100} / month */}
                ${price.unit_amount / 100} / {price.recurring.interval}
              </p>

              {!hidePriceSelect && (
                <button className={classes.subscriptionButton}
                  disabled={isLoading}
                  onClick={() => createSubscription(price.id)}
                >
                  {/* Select */}
                  {i18next.t('subText14', 'Select')}
                </button>
              )}

              {/* {price.recurring.trial_period_days || !hasPastTrial */}
              {/* {!hasPastTrial && price.id === subscriptionBasicPriceId
                && (
                      <button className={classes.subscriptionButton}
                        disabled={isLoading}
                        // onClick={() => createTrialSubscription(price.id)}
                        onClick={() => {
                          setSelectedTrialId(price.id);
                          setShowTrialModal(!showTrialModal);
                        }}
                      >
                        {i18next.t('subText16', 'Free Trial Subscription')}
                      </button>
                    )
              } */}

              <PricesDetail 
                price={price}
                isLoading={isLoading}
              />
              
              <div>{error}</div>

              {isLoading && <div><Loader /></div>}

            </div>
          )
        })}
      </div>

      <div>{error}</div>

      {isLoading && <div><Loader /></div>}

    </div>
  );
}

export default Prices;
